import { browserType } from ".";
import { imagesWEBP } from "./webpImages";

// Zor-Grand, Treo-Zor, ,, , , Rage-+-8.5-kwh, ,
export const whatsappNumber = "919880132233";
export const vehicles = [
  {
    id: 2,
    uuid: "470cf764-c9f8-4f96-bb34-3d885ac3d1e3",
    name: "Piaggio Ape E-Xtra FX",
    vehicle_name: "E-Xtra FX",
    vehicle_path_id: "E-Xtra-FX",
    imageAltNameGallery: "Piaggio Ape E-Xtra FX Loading Vehicle",
    imageAltName360: "E-Xtra FX Loading Vehicle 360",
    businessId: ["milkDistribution", "fish", "cylinder", "waterDistribution"],

    vehicle_id: "2",
    h1: "Piaggio Ape Electrik by Turno | Know more about Piaggio Ape Electrik | Piaggio Ape Electrik Service FAQ",
    page_title: "Get the best price for Piaggio Ape Electrik",
    meta_description:
      "Buy Piaggio Ape Electrik from turno.club. Book a test drive today and get the best offer on Piaggio Ape eletric vehicle. Turno provides the best price and EV loan financing for commercial electric vehicle.",
    rating: 5,
    priceInLakhs: "3.2",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/PiaggioApeE-XtraFX.webp`,
    homeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/PiaggioApeE-XtraFX.webp`,
    storeImageAltName: "Piaggio Ape E-Xtra FX", // needed to change this to show e-xtra fx
    vehicleId: 1,
    prices: {
      Karnataka: {
        flat_bed: {
          price: 3.87,
          emiPrice: 12864,
        },
        full_body: {
          price: 4.31,
          emiPrice: 14565,
        },
        pickup: {
          price: 3.89,
          emiPrice: 12961,
        },
      },
      "Tamil Nadu": {
        flat_bed: {
          price: 3.6,
          emiPrice: 11850,
        },
        full_body: {
          price: 4.2,
          emiPrice: 14123,
        },
        pickup: {
          price: 3.6,
          emiPrice: 11850,
        },
      },
      Telangana: {
        flat_bed: {
          price: 3.75,
          emiPrice: 12399,
        },
        full_body: {
          price: 4.17,
          emiPrice: 14003,
        },
        pickup: {
          price: 3.75,
          emiPrice: 12399,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 3.54,
          emiPrice: 11606,
        },
        full_body: {
          price: 4.23,
          emiPrice: 14257,
        },
        pickup: {
          price: 3.82,
          emiPrice: 12680,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 3.54,
          emiPrice: 11606,
        },
        full_body: {
          price: 4.23,
          emiPrice: 14257,
        },
        pickup: {
          price: 3.82,
          emiPrice: 12680,
        },
      },
      Others: {
        flat_bed: {
          price: 3.87,
          emiPrice: 12864,
        },
        full_body: {
          price: 4.31,
          emiPrice: 14565,
        },
        pickup: {
          price: 3.89,
          emiPrice: 12961,
        },
      },
    },
    desc: "vehicle-page-strong-metal-container-body-and-covered-cabin-with-doors",
    load: "550 Kg",
    range: "80 kms",
    batteryCapacity: "8 kwh",
    batteryVoltage: "51.2 V",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "3.5 hrs",
    warrantyBattery: "3 years",
    chargingType: "Home charger",
    cabinDoors: "Yes",
    variants: "2",
    emiPrice: "12961",
    keyspecs: ["Strong Vehicle Body", "Proven Suspension"],
    topSpeed: "45 km/h",
    price: "₹4,31,370",
    cost: "40 paise/ Km",
    bodyType: "Pickup Van, Delivery Van",

    brand: "Piaggio",
    deal: imagesWEBP?.bestDeals,
    remaining: "4",
    loadInfo: "For heavy load",
    galleryViewImages: [
      {
        id: 1,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio+FX.webp`,
        alt: "Piaggio FX electric auto",
      },
      {
        id: 2,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio+FX-1.webp`,
        alt: "Piaggio FX goods vehicle",
      },
      {
        id: 3,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio+FX-3.webp`,
        alt: "Piaggio FX trucks",
      },
      {
        id: 4,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio+FX-2.webp`,
        alt: "Piaggio FX mini trucks",
      },

      {
        id: 6,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio+FX-4.webp`,
        alt: "Piaggio FX electric auto",
      },
    ],
    latestModel:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/latest_model_piaggio.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/latest_model_piaggio.webp`,
    galleryIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/flipIcons/PiaggioApeE-XtraFX.webp`,

    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}PiaggioVehicleThumbnail/Piaggio.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}PiaggioVehicleThumbnail/Piaggio.jpg`,

    vehicle_logo:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/piaggio_logo.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/png/piaggio_logo.png`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Piaggio+Corousel+imgs/avif/piaggio_flatbed.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Piaggio+Corousel+imgs/compressed/piaggio_flatbed.jpg`,
    businessImage: {
      milkDistribution: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/piaggioFx/milk_container.webp`,
      waterDistribution: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/piaggioFx/water_can.webp`,
      fish: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/piaggioFx/fish.webp`,
      cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/piaggioFx/gas_cylinders.webp`,
    },

    ProductImages: [
      {
        id: 1,
        type: "pickup",
        thumbnailTitle: "Pickup",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/Piaggiofxpickup0.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/Piaggiofxpickup1.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/Piaggiofxpickup2.webp`,
          },
          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/Piaggiofxpickup3.webp`,
          },
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/Piaggiofxpickup4.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/Piaggiofxpickup5.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/mobile/Piaggiofxpickup0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/mobile/Piaggiofxpickup1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/mobile/Piaggiofxpickup2.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/mobile/Piaggiofxpickup3.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/mobile/Piaggiofxpickup4.webp`,
          },

          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/mobile/Piaggiofxpickup5.webp`,
          },

          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/pickup/mobile/Piaggiofxpickup6.webp`,
          },
        ],
      },
      {
        id: 2,
        type: "flat_bed",
        thumbnailTitle: "Flat Bed",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
        imagesDesktop: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/flatbed/Piaggiofxfletbed0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/flatbed/Piaggiofxfletbed1.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/flatbed/mobile/Piaggiofxfletbed0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/flatbed/mobile/Piaggiofxfletbed1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/flatbed/mobile/Piaggiofxfletbed2.webp`,
          },
        ],
      },
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/fullbody/PiaggiofxFullbody0.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/fullbody/PiaggiofxFullbody1.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 10,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/fullbody/mobile/PiaggiofxFullbody0.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/fullbody/mobile/PiaggiofxFullbody1.webp`,
          },
        ],
      },
    ],
    images: [
      // {
      //   id: 1,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Piaggio+Corousel+imgs/avif/piaggio_1.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Piaggio+Corousel+imgs/compressed/piaggio_1.jpg`,
      // },
      {
        id: 9,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p1.webp`,
      },
      {
        id: 10,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p2.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p2.webp`,
      },
      {
        id: 11,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p3.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p3.webp`,
      },
      {
        id: 12,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p4.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p4.webp`,
      },
      {
        id: 13,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p5.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p5.webp`,
      },
      {
        id: 14,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p6.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p6.webp`,
      },
      {
        id: 15,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p7.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p7.webp`,
      },
      {
        id: 16,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p8.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p8.webp`,
      },
      {
        id: 6,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Piaggio+Corousel+imgs/compressed/piaggio_flatbed.webp`,
      },
      {
        id: 8,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Piaggio+Corousel+imgs/compressed/piaggio_pickup.webp`,
      },
      {
        id: 7,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Piaggio+Corousel+imgs/compressed/piaggio_full_body.webp`,
      },
    ],
    view360Images: [
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p1.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p1.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p2.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p3.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p3.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p4.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p4.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p5.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p5.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p6.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p6.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p7.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p7.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/avif/p8.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/piaggio/webp/p8.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi, I'm interested in purchasing a Piaggio Ape Electrik for my business!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Rudhresh",
        work: "Farming and construction",
        city: " Bangalore city",
        description:
          "I am my own boss. My EV takes care of all my business needs.",
        description_name: "- Rudresh, farming and construction",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/piaggio/piaggio-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/piaggio/piaggio-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/byFpeaUlBVM",
      },
    },
  },
  {
    id: 3,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Mahindra Treo",
    vehicle_name: "Treo Zor",
    vehicle_path_id: "Treo-Zor",
    imageAltNameGallery: "mahindra-treo-zor-cargo-three-wheeler",
    imageAltName360: "mahindra-treo-zor-electric-tempo-360",
    businessId: [
      "milkDistribution",
      "waterDistribution",
      "ecommerce",
      "vegetableFruits",
    ],

    businessImage: {
      milkDistribution: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/treoZor/milk_containers.webp`,
      waterDistribution: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/treoZor/water_bottles.webp`,
      ecommerce: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/treoZor/ecommerce.webp`,
      vegetableFruits: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/treoZor/vegetable_crates.webp`,
    },

    vehicle_id: "1",
    h1: "Mahindra Treo Zor by Turno | Know more about Mahindra Treo Zor  | Mahindra Treo Zor  Service FAQ",
    page_title: "Get the best price for Mahindra Treo Zor electric vehicle",
    meta_description:
      "Buy Mahindra Treo Zor from turno.club. Book a test drive today and get the best offer on Mahindra Treo eletric vehicle. Turno provides the best price and EV loan financing for commercial electric vehicle.",
    rating: 5,
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}Retail+Page/webp/mahindra.webp`,
    homeImage: imagesWEBP?.homeZor_Grand,
    storeImageAltName: "Mahindra Treo Zor",
    vehicleId: 2,
    prices: {
      Karnataka: {
        flat_bed: {
          price: 3.3,
          emiPrice: 11412,
        },
        full_body: {
          price: 3.7,
          emiPrice: 12669,
        },
        pickup: {
          price: 3.4,
          emiPrice: 11628,
        },
      },
      "Tamil Nadu": {
        flat_bed: {
          price: 3.3,
          emiPrice: 11412,
        },
        full_body: {
          price: 3.7,
          emiPrice: 12795,
        },
        pickup: {
          price: 3.4,
          emiPrice: 11758,
        },
      },
      Telangana: {
        flat_bed: {
          price: 3.3,
          emiPrice: 11412,
        },
        full_body: {
          price: 3.7,
          emiPrice: 12669,
        },
        pickup: {
          price: 3.4,
          emiPrice: 11628,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 3.1,
          emiPrice: 10355,
        },
        full_body: {
          price: 3.4,
          emiPrice: 11601,
        },
        pickup: {
          price: 3.1,
          emiPrice: 10569,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 3.1,
          emiPrice: 10355,
        },
        full_body: {
          price: 3.4,
          emiPrice: 11601,
        },
        pickup: {
          price: 3.1,
          emiPrice: 10569,
        },
      },
      Others: {
        flat_bed: {
          price: 3.3,
          emiPrice: 11412,
        },
        full_body: {
          price: 3.7,
          emiPrice: 12669,
        },
        pickup: {
          price: 3.4,
          emiPrice: 11628,
        },
      },
    },
    desc: "vehicle-page-trustable-and-Spacious-vehicle-with-easy-entry-and-exit",
    load: "550 Kg",
    range: "80 kms",
    batteryVoltage: "48 V",
    batteryCapacity: "7.2 kwh",
    chargingUnits: "7.2",
    chargingCost: "64.8",
    chargingtime: "3.5 hrs",
    warrantyBattery: "3 years",
    chargingType: "Home charger",
    cabinDoors: "No",
    variants: "3",
    emiPrice: "11,000",
    keyspecs: ["Direct Drive Mode", "Low Noise"],
    topSpeed: "50 km/h",
    price: "₹3,12,214",
    priceInLakhs: `3.1`,
    cost: "40 paise/ Km",
    bodyType: "Flat bed, Semi-cargo, Container",
    brand: "Mahindra",
    remaining: "4",
    loadInfo: "For heavy load",
    galleryViewImages: [
      {
        id: 1,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Mahindra_Gallery/treo_uc_1.webp`,
      },
      {
        id: 2,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Mahindra_Gallery/treo_uc_2.webp`,
      },
      {
        id: 3,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Mahindra_Gallery/treo_uc_3.webp`,
      },
      {
        id: 4,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Mahindra_Gallery/treo_uc_4.webp`,
      },
      {
        id: 5,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Mahindra_Gallery/treo_uc_5.webp`,
      },
      {
        id: 6,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Mahindra_Gallery/treo_uc_6.webp`,
      },
    ],
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/latest_model_treo.webp`,
    galleryIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_zor_grand.webp`,
    // browserType === "Chrome"
    //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_zor_grand.avif`
    //   : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_zor_grand.webp`,
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,

    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahidra_logo.webp`,
    deal: imagesWEBP?.goodDeal,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    ProductImages: [
      {
        id: 1,
        type: "pickup",
        thumbnailTitle: "Pickup",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/Mahindratreopickup0.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/Mahindratreopickup1.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/Mahindratreopickup2.webp`,
          },
          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/Mahindratreopickup3.webp`,
          },
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/Mahindratreopickup4.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/Mahindratreopickup5.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/mobile/Mahindratreopickup0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/mobile/Mahindratreopickup1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/mobile/Mahindratreopickup2.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/mobile/Mahindratreopickup3.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/mobile/Mahindratreopickup4.webp`,
          },

          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/mobile/Mahindratreopickup5.webp`,
          },

          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/pickup/mobile/Mahindratreopickup6.webp`,
          },
        ],
      },
      {
        id: 2,
        type: "flat_bed",
        thumbnailTitle: "Flat Bed",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
        imagesDesktop: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/flatbed/MahindratreoFletbed.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/flatbed/MahindratreoFletbed1.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/flatbed/mobile/MahindratreoFletbed.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/flatbed/mobile/MahindratreoFletbed1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/flatbed/mobile/MahindratreoFletbed2.webp`,
          },
        ],
      },
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/fullbody/MahindratreoFullbody0.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/fullbody/Mahindratreofullbody1.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 10,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/fullbody/mobile/Mahindratreofullbody.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/fullbody/mobile/Mahindratreofullbody1.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/fullbody/mobile/Mahindratreofullbody2.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/fullbody/mobile/Mahindratreofullbody3.webp`,
          },
        ],
      },
    ],
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,
      },
      {
        id: 11,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m2.webp`,
      },
      {
        id: 12,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,
      },
      {
        id: 13,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m4.webp`,
      },
      {
        id: 14,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m5.webp`,
      },
      {
        id: 15,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m6.webp`,
      },
      {
        id: 16,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
      },
      {
        id: 17,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m8.webp`,
      },
      {
        id: 18,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m9.webp`,
      },
      {
        id: 7,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
      },
      {
        id: 9,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_pickup.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_pickup.jpg`,
      },
      {
        id: 8,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_full_body.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_full_body.jpg`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,

      `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m2.webp`,

      `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,

      `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m4.webp`,

      `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m5.webp`,

      `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m6.webp`,

      `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,

      `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m8.webp`,

      `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m9.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi, I'm interested in purchasing a Mahindra Treo Zor EV for my business!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Girish",
        work: "Logistics",
        city: " Bangalore city",
        description:
          "I really liked Turno’s fast loan process.I got my EV in 6 days",
        description_name: "- Girish L, Logistics",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },
  {
    id: 4,
    uuid: "094a1d11-d17b-4c80-b214-87ca5b2139ac",
    name: "E-TRIO Touro MAX++", //this data we need to change
    vehicle_name: "Touro MAX++",
    vehicle_path_id: "Touro-MAX",
    imageAltNameGallery: "Etrio-Touro-Max+-electric three wheeler",
    imageAltName360: "Etrio-Touro-Max+-electric-tempo-360",
    businessId: ["milkDistribution", "fish"],
    businessImage: {
      milkDistribution: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/etrioTouroMax/milk_can.webp`,
      fish: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/etrioTouroMax/fishes.webp`,
    },

    vehicle_id: "4",
    h1: "Etrio Touro Max+  by Turno | Know more about Etrio Touro Max+",
    page_title: "Get the best price for Etrio Touro electric vehicle",
    meta_description:
      "Buy Etrio Touro from turno.club. Book a test drive today and get the best offer on Etrio Touro eletric vehicle. Turno provides the best price and EV loan financing for commercial electric vehicle.",
    rating: 4.7,
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}Retail+Page/webp/etrio.webp`,
    homeImage: imagesWEBP.homeEtrio,
    storeImageAltName: "E-trio Touro Max++",
    vehicleId: 3,
    prices: {
      Karnataka: {
        full_body: {
          price: 4.2,
          emiPrice: 14329,
        },
        pickup: {
          price: 4.1,
          emiPrice: 13861,
        },
      },
      "Tamil Nadu": {
        full_body: {
          price: 4.2,
          emiPrice: 14329,
        },
        pickup: {
          price: 4.1,
          emiPrice: 13861,
        },
      },
      Telangana: {
        full_body: {
          price: 3.9,
          emiPrice: 13610,
        },
        pickup: {
          price: 3.6,
          emiPrice: 12174,
        },
      },
      Maharashtra: {
        full_body: {
          price: 4.2,
          emiPrice: 14329,
        },
        pickup: {
          price: 4.1,
          emiPrice: 13861,
        },
      },
      "Uttar Pradesh": {
        full_body: {
          price: 4.2,
          emiPrice: 14329,
        },
        pickup: {
          price: 4.1,
          emiPrice: 13861,
        },
      },
      Others: {
        full_body: {
          price: 4.2,
          emiPrice: 14329,
        },
        pickup: {
          price: 4.1,
          emiPrice: 13861,
        },
      },
    },
    desc: "vehicle-page-strong-body-design-and-offer-stable-driving-exp",
    load: "550 Kg",
    range: "100 kms",
    batteryVoltage: "48 V",
    batteryCapacity: "10.24 kwh",
    chargingUnits: "10.24",
    chargingCost: "92.16",
    chargingtime: "4 hrs",
    warrantyBattery: "3 years",
    chargingType: "Home charger",
    cabinDoors: "Yes",
    variants: "2",
    emiPrice: "11,600",
    keyspecs: [
      "Powerful Drivetrain",
      "Automatic Transmission",
      "Digital Cluster",
    ],
    topSpeed: "50 km/h",
    price: "₹3,21,300",
    priceInLakhs: "3.2",
    cost: "45 paise/ Km",
    bodyType: "Semi-cargo, Container",
    brand: "Etrio",
    remaining: "4",
    loadInfo: "For heavy load",
    galleryViewImages: [
      {
        id: 1,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Etrio_Gallery/etrio_1.webp`,
      },
      {
        id: 2,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Etrio_Gallery/etrio_2.webp`,
      },
      {
        id: 3,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Etrio_Gallery/etrio_3.webp`,
      },
      {
        id: 4,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Etrio_Gallery/etrio_4.webp`,
      },
    ],
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/latest_model_etrio.webp`,

    galleryIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_etrio.webp`,
    deal: imagesWEBP?.bestDeals,
    // browserType === "Chrome"
    //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_etrio.avif`
    //   : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_etrio.webp`,
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}ETrioVehicleThumbnail/e1.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}ETrioVehicleThumbnail/e1.jpg`,

    vehicle_logo:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/Frame_1.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/png/Frame.png`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Etrio+Corousel+imgs/avif/etrio_pickup_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Etrio+Corousel+imgs/compressed/etrio_pickup_2.jpg`,

    ProductImages: [
      {
        id: 1,
        type: "pickup",
        thumbnailTitle: "Pickup",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/pickup/etriopickup0.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/pickup/etriopickup1.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/pickup/etriopickup2.webp`,
          },
          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/pickup/etriopickup3.webp`,
          },
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/pickup/etriopickup4.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/pickup/mobile/etriopickup0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/pickup/mobile/etriopickup1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/pickup/mobile/etriopickup2.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/pickup/mobile/etriopickup3.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/pickup/mobile/etriopickup4.webp`,
          },

          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/pickup/mobile/etriopickup5.webp`,
          },
        ],
      },
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/fullbody/etrioFullbody0.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/fullbody/etrioFullbody1.webp`,
          },
          {
            id: 19,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/fullbody/etrioFullbody2.webp`,
          },
          {
            id: 20,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/fullbody/etrioFullbody3.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 10,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/fullbody/mobile/etrioFullbody0.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/fullbody/mobile/etrioFullbody1.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/fullbody/mobile/etrioFullbody2.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/fullbody/mobile/etrioFullbody3.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/fullbody/mobile/etrioFullbody4.webp`,
          },
        ],
      },
    ],
    images: [
      {
        id: 4,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/webp/e1.webp`,
      },
      {
        id: 5,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/webp/e2.webp`,
      },
      {
        id: 6,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/avif/e3.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/webp/e3.webp`,
      },
      {
        id: 7,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/avif/e4.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/webp/e4.webp`,
      },
      {
        id: 2,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Etrio+Corousel+imgs/avif/etrio_pickup_2.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Etrio+Corousel+imgs/compressed/etrio_pickup_2.jpg`,
      },
      {
        id: 1,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Etrio+Corousel+imgs/avif/etrio2.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Etrio+Corousel+imgs/compressed/etrio2.jpg`,
      },
      {
        id: 3,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Etrio+Corousel+imgs/avif/etrio_full_body.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Etrio+Corousel+imgs/compressed/etrio_full_body.jpg`,
      },
    ],
    view360Images: [
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/avif/e1.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/webp/e1.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/avif/e2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/webp/e2.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/avif/e3.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/webp/e3.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/avif/e4.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/etrio/webp/e4.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi, I'm interested in purchasing a E-TRIO Touro MAX+ EV for my business!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Vasanth Kumar",
        work: "Logistics",
        city: " Bangalore city",
        description: "Turno is the solution to saving money on fuel.",
        description_name: "- Vasanth Kumar Logistics",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/etrio/etrio-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/etrio/etrio-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/3auVEm3e9Xk",
      },
    },
  },
  {
    id: 6,
    uuid: "742ddf3c-1c2a-48cc-ad54-b5773e408ba2",
    name: "OSM Rage + 8.5 KWh",
    vehicle_name: "Rage + 8.5 KWh",
    vehicle_path_id: "Rage-+-8.5-kwh",
    imageAltNameGallery: "OSM-Rage+-electric-loading vehicle",
    imageAltName360: "OSM-Rage+-electric-tempo-360",
    businessId: ["milkDistribution", "waterDistribution"],
    businessImage: {
      milkDistribution: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/osmRage/osm_milk_containers.webp`,
      waterDistribution: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/osmRage/osm_water_bottles.webp`,
    },

    vehicle_id: "3",
    h1: "OSM Rage+ by Turno | Know more about OSM Rage+",
    page_title: "Get the best price for OSM Rage+ electric vehicle",
    meta_description:
      "Buy OSM Rage+ from turno.club. Book a test drive today and get the best offer on OSM Rage+ eletric vehicle. Turno provides the best price and EV loan financing for commercial electric vehicle.",
    rating: 4.5,
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}Retail+Page/webp/osm.webp`,
    homeImage: imagesWEBP.homeOsm,
    storeImageAltName: "OSM Rage + 8.5 KWh",
    vehicleId: 4,
    deal: imagesWEBP?.bestPrice,
    prices: {
      Karnataka: {
        flat_bed: {
          price: 3.5,
          emiPrice: 12073,
        },
        full_body: {
          price: 3.7,
          emiPrice: 12791,
        },
        pickup: {
          price: 3.6,
          emiPrice: 12304,
        },
      },
      "Tamil Nadu": {
        flat_bed: {
          price: 4.1,
          emiPrice: 13772,
        },
        full_body: {
          price: 4.3,
          emiPrice: 14630,
        },
        pickup: {
          price: 4.1,
          emiPrice: 14109,
        },
      },
      Telangana: {
        flat_bed: {
          price: 3.5,
          emiPrice: 12073,
        },
        full_body: {
          price: 3.7,
          emiPrice: 12791,
        },
        pickup: {
          price: 3.6,
          emiPrice: 12304,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 4.1,
          emiPrice: 14066,
        },
        full_body: {
          price: 4.3,
          emiPrice: 14733,
        },
        pickup: {
          price: 4.2,
          emiPrice: 14240,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 4.1,
          emiPrice: 14066,
        },
        full_body: {
          price: 4.3,
          emiPrice: 14733,
        },
        pickup: {
          price: 4.2,
          emiPrice: 14240,
        },
      },
      Others: {
        flat_bed: {
          price: 3.5,
          emiPrice: 12073,
        },
        full_body: {
          price: 3.7,
          emiPrice: 12791,
        },
        pickup: {
          price: 3.6,
          emiPrice: 12304,
        },
      },
    },
    desc: "vehicle-page-modern-design-and-space",
    load: "500 Kg",
    range: "120 kms",
    batteryVoltage: "48 V",
    batteryCapacity: "10.8 kwh",
    chargingUnits: "10.8",
    chargingCost: "97.2",
    chargingtime: "3 hrs",
    warrantyBattery: "3 years",
    chargingType: "Home charger",
    cabinDoors: "yes",
    variants: "8",
    emiPrice: "13,600",
    keyspecs: ["Boost Mode", "Wide Window screen", "Regenerative Braking"],
    topSpeed: "45 km/h",
    price: "₹3,60,750",
    priceInLakhs: "3.6",
    cost: "50 paise/ Km",
    bodyType: "Flat bed, Semi-cargo, Container",
    brand: "OSM",
    remaining: "4",
    loadInfo: "For heavy load",
    galleryViewImages: [
      {
        id: 1,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_1.webp`,
      },
      {
        id: 2,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_2.webp`,
      },
      {
        id: 3,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_3.webp`,
      },
      {
        id: 4,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_4.webp`,
      },
      {
        id: 5,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_5.webp`,
      },
      {
        id: 6,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_6.webp`,
      },
    ],
    latestModel:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/latest_model_rageplus_pickup_1.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/latest_model_rageplus_pickup_1.webp`,
    galleryIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_rage_plus.webp`,
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}OSMVehicleThumbnail/OSM.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}OSMVehicleThumbnail/OSM.jpg`,

    vehicle_logo:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/Group-1000004749.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/png/Group+1000004749.png`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/OSM+Corousel+imgs/avif/osm_flatbed.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/OSM+Corousel+imgs/compressed/osm_flatbed.jpg`,
    images: [
      {
        id: 10,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/avif/o1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/webp/o1.webp`,
      },
      {
        id: 11,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/avif/o2.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/webp/o2.webp`,
      },
      {
        id: 12,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/avif/o3.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/webp/o3.webp`,
      },
      {
        id: 13,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/avif/o4.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/webp/o4.webp`,
      },
      {
        id: 14,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/avif/o5.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/webp/o5.webp`,
      },

      {
        id: 1,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/OSM+Corousel+imgs/avif/osm_1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/OSM+Corousel+imgs/compressed/osm_1.jpg`,
      },
      {
        id: 8,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/OSM+Corousel+imgs/avif/osm_full_body_2.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/OSM+Corousel+imgs/compressed/osm_full_body_2.jpg`,
      },
      {
        id: 7,
        src:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/OSM+Corousel+imgs/avif/osm_flatbed.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/OSM+Corousel+imgs/compressed/osm_flatbed.jpg`,
      },
    ],
    view360Images: [
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/avif/o1.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/webp/o1.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/avif/o2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/webp/o2.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/avif/o3.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/webp/o3.webp`,
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/avif/o4.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/webp/o4.webp`,

      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/avif/o5.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/osm/webp/o5.webp`,
    ],

    link: `https://wa.me/+${whatsappNumber}?text= Hi, I'm interested in purchasing a OSM Rage+ EV for my business!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Vijay Kumar",
        work: "Water Distribution",
        city: " Bangalore city",
        description:
          "High Range Commercial Electric 3-Wheeler Vehicle On The Road",
        description_name: "",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/osm/osm-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/osm/osm-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/xf7w9FdaaC8",
      },
    },
    vehicleDescription: [
      "E-trio Touro Max is a 3 wheeler cargo auto and one of the bestselling electric cargo vehicles from E-trio. The Touro Max electric vehicle has several advantages over conventional internal combustion engine (ICE) vehicles. The first and most obvious advantage is that it has zero emissions and doesn't require fuels. As electricity is less expensive than gasoline, electric vehicles can be a great choice for those looking for a cargo vehicle with a lower operating cost. This cargo electrical auto is also considerably more comfortable to run due to its gearless and noiseless operations.",
      " The E-trio Touro Max cargo 3-wheeler boasts a spacious cargo area measuring 5.68 feet in length, 4.72 feet in width, and 1.25 feet in height, enabling it to transport a variety of items. It is equipped with a powerful engine, providing a maximum speed of 45 km/h",
      "This electric three wheeler is well known for its high load bearing capacity. This commercial EV can be used for transporting gas cylinders, water cans, logistics, and many others.",
      " To know more about E-trio Touro Max or to book it, contact Turno today. ",
    ],
  },
];

export const newVehicles = [
  {
    id: 13,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Greaves Eltra",
    vehicle_name: "Eltra",
    vehicle_path_id: "Eltra",
    imageAltNameGallery: "Greaves Eltra Electric Loading Vehicle",
    imageAltName360: "Eltra Loading Vehicle 360",
    businessId: ["cylinder", "ecommerce"],
    businessImage: {
      cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/greavesEltra/eltra_gas_cylinders.webp`,
      ecommerce: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/greavesEltra/eltra_container_2.webp`,
    },
    vehicle_id: "1",
    h1: "Greaves Eltra | Electric Goods Auto Price & Features - Turno",
    page_title: "Greaves Eltra",
    meta_description:
      "Explore the Greaves Eltra, a powerful 3-wheeler cargo vehicle with impressive specs. Get special offers from Turno and unleash its potential for your needs.",
    rating: 5,
    priceInLakhs: "3.99",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves.webp`,
    storeImageAltName: "Greaves Eltra",
    vehicleId: 13,
    deal: imagesWEBP?.bestPrice,
    prices: {
      Karnataka: {
        flat_bed: {
          price: 3.99,
          emiPrice: 12590,
        },
        full_body: {
          price: 4.35,
          emiPrice: 13902,
        },
        pickup: {
          price: 4.2,
          emiPrice: 13278,
        },
      },
      "Tamil Nadu": {
        flat_bed: {
          price: 3.99,
          emiPrice: 12590,
        },
        full_body: {
          Price: 4.34,
          emiPrice: 13902,
        },
        pickup: {
          price: 4.17,
          emiPrice: 13278,
        },
      },
      Telangana: {
        flat_bed: {
          price: 3.99,
          emiPrice: 12590,
        },
        full_body: {
          price: 4.34,
          emiPrice: 13902,
        },
        pickup: {
          price: 4.17,
          emiPrice: 13278,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 3.99,
          emiPrice: 12590,
        },
        full_body: {
          price: 4.34,
          emiPrice: 13902,
        },
        pickup: {
          price: 4.17,
          emiPrice: 13278,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 3.99,
          emiPrice: 12590,
        },
        full_body: {
          price: 4.34,
          emiPrice: 13902,
        },
        pickup: {
          price: 4.17,
          emiPrice: 13278,
        },
      },
      Others: {
        flat_bed: {
          price: 3.99,
          emiPrice: 12590,
        },
        full_body: {
          price: 4.34,
          emiPrice: 13902,
        },
        pickup: {
          price: 4.17,
          emiPrice: 13278,
        },
      },
    },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    load: "530 Kg",
    range: "105 kms",
    batteryVoltage: "51.2 V",
    batteryCapacity: "10.8 kwh",
    chargingUnits: "-",
    chargingCost: "86",
    chargingtime: "5 hrs",
    warrantyBattery: "3 years",
    chargingType: "Home charger",
    cabinDoors: "Yes",
    variants: "3",
    emiPrice: "12,590",
    keyspecs: ["Powerful Pickup", "Enhanced Motor"],
    topSpeed: "50 km/h",
    price: "₹3,98,734",
    cost: "86 paise/ Km",
    bodyType: "Pickup Van, Delivery Van, Flat bed",

    brand: "Greaves",

    remaining: "4",
    loadInfo: "For heavy load",
    galleryViewImages: [
      {
        id: 1,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/pickup/Greavespickup0.webp`,
      },
      {
        id: 2,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/pickup/Greavespickup1.webp`,
      },
      {
        id: 3,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/pickup/Greavespickup2.webp`,
      },
    ],
    vehiclePageImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves.webp`,

    galleryIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greavesgallery.webp`,

    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandLogo/greaveslogo.webp`,

    image: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves.webp`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi there!👋 I am interested in purchasing Greaves Eltra for my business 🚚!`,
    ProductImages: [
      {
        id: 1,
        type: "pickup",
        thumbnailTitle: "Pickup",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/pickup/Greavespickup0.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/pickup/Greavespickup1.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/pickup/Greavespickup2.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/pickup/mobile/Greavespickup0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/pickup/mobile/Greavespickup1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/pickup/mobile/Greavespickup2.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/pickup/mobile/Greavespickup3.webp`,
          },
        ],
      },
      {
        id: 2,
        type: "flat_bed",
        thumbnailTitle: "Flat Bed",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
        imagesDesktop: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/Greavesfletbed0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/Greavesfletbed1.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/Greavesfletbed2.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/mobile/Greavesfletbed0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/mobile/Greavesfletbed1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/mobile/Greavesfletbed2.webp`,
          },
        ],
      },
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/fullbody/GreavesFullbody0.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/fullbody/GreavesFullbody1.webp`,
          },
          {
            id: 19,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/fullbody/GreavesFullbody2.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 10,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/fullbody/mobile/GreavesFullbody0.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/fullbody/mobile/GreavesFullbody1.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/fullbody/mobile/GreavesFullbody2.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/fullbody/mobile/GreavesFullbody3.webp`,
          },
        ],
      },
    ],
    testimonials: {
      customer_1: {
        id: 1,
        name: "Kamble",
        work: "Cooked Food Distribution",
        city: " Bangalore city",
        description:
          "I didn't know which gaadi to buy for my business. Turno helped me.",
        description_name: "- Kamble, Cooked Food Distribution",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },

  {
    id: 1,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Euler HiLoad",
    vehicle_name: "HiLoad DV 130 Cu. Ft",
    vehicle_path_id: "HiLoadDV",
    imageAltNameGallery: "Euler HiLoad Commercial Electric Gadi",
    imageAltName360: "Euler HiLoad Commercial Electric Gadi 360",
    businessId: ["cylinder", "vegetableFruits"],
    businessImage: {
      cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/gas_cylinders.webp`,
      vegetableFruits: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/vegetables.webp`,
    },
    vehicle_id: "6",
    h1: "Euler HiLoad EV: Price & features | Electric three wheeler- Turno",
    meta_description:
      "Learn about the Euler HiLoad EV, an electric three-wheeler, and its price and features from Turno. Book your test drive now!",
    rating: 4.6,
    priceInLakhs: "4.14",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
    homeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
    storeImageAltName: "Euler Hiload",
    vehicleId: 5,
    deal: imagesWEBP?.bestPrice,
    prices: {
      Karnataka: {
        full_body: {
          price: 4.51,
          emiPrice: 15321,
        },
        pickup: {
          price: 4.14,
          emiPrice: 13911,
        },
      },
      "Tamil Nadu": {
        full_body: {
          price: 4.56,
          emiPrice: 15501,
        },
        pickup: {
          Price: 4.36,
          emiPrice: 14737,
        },
      },
      Telangana: {
        full_body: {
          price: 4.5,
          emiPrice: 15269,
        },
        pickup: {
          price: 4.3,
          emiPrice: 14500,
        },
      },
      Maharashtra: {
        full_body: {
          price: 4.29,
          emiPrice: 14466,
        },
        pickup: {
          price: 4.14,
          emiPrice: 13893,
        },
      },
      "Uttar Pradesh": {
        full_body: {
          price: 4.29,
          emiPrice: 14466,
        },
        pickup: {
          price: 4.14,
          emiPrice: 13893,
        },
      },
      Others: {
        full_body: {
          price: 4.51,
          emiPrice: 12386,
        },
        pickup: {
          price: 4.14,
          emiPrice: 11911,
        },
      },
    },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    load: "688 Kg",
    range: "120 kms",
    batteryVoltage: "72 V",
    batteryCapacity: "13 kwh",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "3.5-4 hrs",
    warrantyBattery: "3 years",
    chargingType: "Portable charger",
    cabinDoors: "Yes",
    variants: "3",
    emiPrice: "15,321",
    keyspecs: ["Fast Charging", "Disc Brakes"],
    topSpeed: "45 km/h",
    price: "₹4,51,179",
    cost: "40 paise/ Km",
    bodyType: "Flat bed, Pickup Van, Delivery Van",
    brand: "Euler",
    remaining: "4",
    loadInfo: "For heavy load",

    ProductImages: [
      {
        id: 1,
        type: "pickup",
        thumbnailTitle: "Pickup",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/pickup/Eulerpickup0.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/pickup/Eulerpickup0.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/pickup/Eulerpickup1.webp`,
          },
          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/pickup/Eulerpickup2.webp`,
          },
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/pickup/Eulerpickup3.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/pickup/mobile/Eulerpickup0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/pickup/mobile/Eulerpickup1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/pickup/mobile/Eulerpickup2.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/pickup/mobile/Eulerpickup3.webp`,
          },
        ],
      },
      // {
      //   id: 2,
      //   type: "flat_bed",
      //   thumbnailTitle: "Flat Bed",
      //   thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,
      //   thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
      //   thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
      //   imagesDesktop: [
      //     {
      //       id: 11,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/Greavesfletbed0.webp`,
      //     },
      //     {
      //       id: 12,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/Greavesfletbed1.webp`,
      //     },
      //     {
      //       id: 12,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/Greavesfletbed2.webp`,
      //     },
      //   ],

      //   imagesMobile: [
      //     {
      //       id: 11,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/mobile/Greavesfletbed0.webp`,
      //     },
      //     {
      //       id: 12,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/mobile/Greavesfletbed1.webp`,
      //     },
      //     {
      //       id: 13,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/mobile/Greavesfletbed2.webp`,
      //     },
      //   ],
      // },
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/Eulerfullbody0.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/Eulerfullbody1.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 10,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/mobile/Eulerfullbody0.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/mobile/Eulerfullbody1.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/mobile/Eulerfullbody2.webp`,
          },
        ],
      },
    ],
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,
    galleryIcon:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/flipIcons/EulerEulerHiLoad.webp`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_rage_plus.webp`,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/euler_logo.webp`,

    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerEulerHiLoad.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi there!👋I am interested in purchasing OSM Rage + for my business 🚚!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Parthasarathi",
        work: "Garment Business",
        city: " Bangalore city",
        description:
          "Turno's representatives even visited my office to collect the necessary documents for the EV loan.",
        description_name: "- Parthasarathi, Garment Business",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },

  // 3

  {
    id: 3,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "OSM Rage +",
    vehicle_name: "Rage + 10.8 Kwh",
    vehicle_path_id: "Rage-+-10.8-kwh",
    imageAltNameGallery: "OSM Rage + Electric Tempo",
    imageAltName360: "Rage + Electric Tempo 360",
    businessId: ["milkDistribution", "waterDistribution"],
    businessImage: {
      milkDistribution: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/osmRage/osm_milk_containers.webp`,
      waterDistribution: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/osmRage/osm_water_bottles.webp`,
    },
    vehicle_id: "4",
    h1: "OSM Rage+: EV Loader Price, Mileage, Charging Time & specs- Turno",
    meta_description:
      "Looking for the OSM Rage+? Check out its price, mileage, charging time, and key specs at Turno. Get all the details you need to make an informed decision.",
    rating: 4.8,
    priceInLakhs: "4.1",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/OSMRage10kwh.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/OSMRage10kwh.webp`,
    storeImageAltName: "OSM Rage + 10.8 Kwh",
    vehicleId: 6,
    deal: imagesWEBP?.goodDeal,
    prices: {
      Karnataka: {
        flat_bed: {
          price: 3.99,
          emiPrice: 13336,
        },
        full_body: {
          price: 4.12,
          emiPrice: 13817,
        },
        pickup: {
          price: 4.3,
          emiPrice: 14499,
        },
      },
      "Tamil Nadu": {
        flat_bed: {
          price: 4.36,
          emiPrice: 14753,
        },
        full_body: {
          price: 4.55,
          emiPrice: 15451,
        },
        pickup: {
          Price: 4.36,
          emiPrice: 14753,
        },
      },
      Telangana: {
        flat_bed: {
          price: 4.05,
          emiPrice: 13539,
        },
        full_body: {
          price: 4.25,
          emiPrice: 14302,
        },
        pickup: {
          price: 4.1,
          emiPrice: 13729,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 3.95,
          emiPrice: 13172,
        },
        full_body: {
          price: 4.21,
          emiPrice: 14160,
        },
        pickup: {
          price: 4.06,
          emiPrice: 13577,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 3.95,
          emiPrice: 13172,
        },
        full_body: {
          price: 4.21,
          emiPrice: 14160,
        },
        pickup: {
          price: 4.06,
          emiPrice: 13577,
        },
      },
      Others: {
        flat_bed: {
          price: 4.05,
          emiPrice: 13539,
        },
        full_body: {
          price: 4.25,
          emiPrice: 14302,
        },
        pickup: {
          price: 4.1,
          emiPrice: 13729,
        },
      },
    },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    load: "550 Kg",
    range: "120 km",
    batteryVoltage: "48 V",
    batteryCapacity: "10.8 kwh",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "4 hrs",
    warrantyBattery: "3 years",
    chargingType: "Portable charger",
    cabinDoors: "Yes",
    brand: "OSM",
    variants: "4",
    emiPrice: "14,499",
    keyspecs: ["Regenerative Braking", "Aerodynamic Design"],
    topSpeed: "50 km/h",
    price: "₹4,29,650",
    cost: "40 paise/ Km",
    bodyType: "Pickup Van, Delivery Van, Frost, Garbage Tipper",
    remaining: "4",
    loadInfo: "For heavy load",
    vehiclePageImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/OSMRage10kwh.webp`,
    galleryViewImages: [
      {
        id: 1,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_1.webp`,
      },
      {
        id: 2,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_2.webp`,
      },
      {
        id: 3,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_3.webp`,
      },
      {
        id: 4,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_4.webp`,
      },
      {
        id: 5,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_5.webp`,
      },
      {
        id: 6,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Osm_Gallery/osm_uc_6.webp`,
      },
    ],
    galleryIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/flipIcons/OSMRage10.webp`,
    vehicle_logo:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/Group-1000004749.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/png/Group+1000004749.png`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/OSMRage10kwh.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/OSMRage10kwh.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi there!👋I am interested in purchasing OSM Rage + for my business 🚚!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Sunil",
        work: "Delivery",
        city: " Bangalore city",
        description:
          "I got smooth gadi financing with Turno. With the assured resale scheme, I can ride my electric auto worry-free",
        description_name: "- Sunil, Delivery",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },

  // 4
  {
    id: 4,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "OSM Rage + Frost",
    vehicle_name: "Rage + Frost",
    vehicle_path_id: "Rage-+-Frost",
    imageAltNameGallery: "OSM Rage + Frost Electric Commercial Vehicle",
    imageAltName360: "OSM Rage + Frost Electric Commercial Vehicle 360",
    vehicle_id: "4",
    brand: "OSM",
    h1: "OSM Rage + Frost: Electric Vehicle Price, Mileage & Range  - Turno",
    meta_description:
      "Looking for OSM Rage + Frost?. Get details about the electric vehicle price, mileage, and range from Turno, the best electric vehicle dealers.",
    rating: 4.8,
    priceInLakhs: "8.43",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/frost-sm1 osm.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/frost-sm1 osm.webp`,
    storeImageAltName: "OSM Rage + Frost",
    vehicleId: 7,
    deal: imagesWEBP?.bestPrice,
    prices: {
      Karnataka: {
        full_body: {
          price: 8.41,
          emiPrice: 30209,
        },
      },
      "Tamil Nadu": {
        full_body: {
          price: 8.41,
          emiPrice: 30209,
        },
      },
      Telangana: {
        flat_bed: {
          price: 8.41,
          emiPrice: 30209,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 8.41,
          emiPrice: 30209,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 8.41,
          emiPrice: 30209,
        },
      },
      Others: {
        full_body: {
          price: 8.41,
          emiPrice: 30209,
        },
      },
    },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    load: "500 Kg",
    range: "120 km",
    batteryCapacity: "10.8 kwh",
    batteryVoltage: "48 V",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "4 hrs",
    warrantyBattery: "3 years",
    chargingType: "Portable charger",
    cabinDoors: "Yes",
    variants: "4",
    emiPrice: "30,209",
    keyspecs: ["Portable Charging", "Boost Mode"],
    topSpeed: "50 km/h",
    price: "₹8,41,000",
    cost: "40 paise/ Km",
    bodyType: "Pickup Van, Delivery Van, Frost, Garbage Tipper",
    brand: "OSM",
    remaining: "4",
    loadInfo: "For heavy load",
    vehiclePageImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/OSMRage10kwh.webp`,

    vehicle_logo:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/Group-1000004749.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/png/Group+1000004749.png`,
    galleryIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/flipIcons/frost-sm1 osm.webp`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/frost-sm1 osm.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/frost-sm1 osm.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi there!👋 I am interested in purchasing OSM Rage + Frost for my business 🚚!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Charan",
        work: "Food Stall Business",
        city: " Bangalore city",
        description:
          "Turno helped me choose the best gadi for my business. Now I travel 100km at just ₹70.",
        description_name: "- Charan, Food Stall Business",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },

  // 5

  {
    id: 5,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Etrio Tuoro Max++",
    vehicle_name: "Tuoro max++",
    vehicle_path_id: "Touro-MAX",
    imageAltNameGallery: "Etrio-Tuoro-Max++-Commercial-Auto",
    imageAltName360: "Etrio-Tuoro-Max++-Commercial-Auto-360",
    //businessId: ["milkDistribution", "fish"],

    vehicle_id: "1",
    h1: "E-Trio Touro Max ++- Electric cargo 3 wheeler price & EMI -Turno",
    page_title: "E-Trio Tuoro Max++",
    meta_description:
      "Looking for the E-Trio Touro Max++ electric gadi in India? Find out the best price, EMI options, range, and battery and loading capacity from Turno!",
    rating: 4.5,
    priceInLakhs: "4.1",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/KwhE-TrioTuoroMax%2B%2B.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/KwhE-TrioTuoroMax%2B%2B.webp`,
    storeImageAltName: "Etrio Tuoro Max++",
    deal: imagesWEBP?.goodDeal,
    vehicleId: 14,
    prices: {
      Karnataka: {
        flat_bed: {
          price: 3.94,
          emiPrice: 13145,
        },
        full_body: {
          price: 4.36,
          emiPrice: 14749,
        },
        pickup: {
          price: 3.98,
          emiPrice: 13306,
        },
      },
      "Tamil Nadu": {
        flat_bed: {
          price: 3.83,
          emiPrice: 12752,
        },
        full_body: {
          price: 4.18,
          emiPrice: 14034,
        },
        pickup: {
          Price: 3.88,
          emiPrice: 12912,
        },
      },
      Telangana: {
        flat_bed: {
          price: 3.87,
          emiPrice: 12868,
        },
        full_body: {
          price: 4.25,
          emiPrice: 14321,
        },
        pickup: {
          price: 3.91,
          emiPrice: 13032,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 3.83,
          emiPrice: 12714,
        },
        full_body: {
          price: 4.17,
          emiPrice: 13997,
        },
        pickup: {
          price: 3.87,
          emiPrice: 12874,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 3.83,
          emiPrice: 12714,
        },
        full_body: {
          price: 4.17,
          emiPrice: 13997,
        },
        pickup: {
          price: 3.87,
          emiPrice: 12874,
        },
      },
      Others: {
        flat_bed: {
          price: 4.32,
          emiPrice: 14604,
        },
        full_body: {
          price: 4.68,
          emiPrice: 15964,
        },
        pickup: {
          price: 4.38,
          emiPrice: 14803,
        },
      },
    },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    load: "550 Kg",
    range: "100 kms",
    batteryCapacity: "10.8 kwh",
    batteryVoltage: "48 V",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "4 hrs",
    warrantyBattery: "100000 km",
    chargingType: "Home charger",
    cabinDoors: "Yes",
    variants: "3",
    emiPrice: "14,749",
    keyspecs: ["Lithium-ion Battery", "Clutch-less Drive"],
    topSpeed: "50 km/h",
    price: "₹4,36,200",
    cost: "40 paise/ Km",
    bodyType: "Flatbed, Half Loader, Full Loader ",
    brand: "E-Trio",
    remaining: "4",
    loadInfo: "For heavy load",
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,
    galleryIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/flipIcons/KwhE-TrioTuoroMax.webp`,
    vehicle_logo:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/Frame_1.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/png/Frame.png`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/KwhE-TrioTuoroMax%2B%2B.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/KwhE-TrioTuoroMax%2B%2B.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi there!👋 I am interested in purchasing E-Trio Touro Max++ for my business 🚚!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Deenadayal",
        work: "Catering Business",
        city: "Bengaluru",
        description: "Gadi resale is what makes mefeel good about Turno.",
        description_name: "- Deenadayal, Catering Business",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },

  // 7
  {
    id: 7,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Piaggio Ape E Xtra FX Max",
    vehicle_name: "E-Xtra FX Max",
    vehicle_path_id: "E-Xtra-FX-Max",
    imageAltNameGallery: "Piaggio Ape E-Xtra FX Max Loading Vehicle",
    imageAltName360: "E-Xtra FX Max Loading Vehicle 360",
    businessId: ["ecommerce", "vegetableFruits"],
    businessImage: {
      ecommerce: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/piaggioFxMax/faxmaxEcommerce.webp`,
      vegetableFruits: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/piaggioFxMax/vegetables.webp`,
    },
    vehicle_id: "1",
    h1: "Piaggio Ape E Xtra FX | Electric Goods Auto Price & Features - Turno",
    page_title: "Piaggio Ape E-Xtra FX Max",
    meta_description:
      "Explore the Piaggio Ape E Xtra FX Max, a powerful 3-wheeler cargo vehicle with impressive specs. Get special offers from Turno and unleash its potential for your needs.",
    rating: 5,
    priceInLakhs: "3.89",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/PiaggioApeE-XtraFXMax.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/PiaggioApeE-XtraFXMax.webp`,
    storeImageAltName: "Piaggio Ape E Xtra FX Max",
    newLaunchImage: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/piaggioNewLaunch.webp`,
    vehicleId: 8,
    deal: imagesWEBP?.bestPrice,
    prices: {
      Karnataka: {
        flat_bed: {
          price: 3.76,
          emiPrice: 12459,
        },
        full_body: {
          price: 4.2,
          emiPrice: 14140,
        },
        pickup: {
          price: 3.78,
          emiPrice: 12536,
        },
      },
      "Tamil Nadu": {
        flat_bed: {
          price: 3.65,
          emiPrice: 12046,
        },
        full_body: {
          price: 4.35,
          emiPrice: 14697,
        },
        pickup: {
          Price: 3.94,
          emiPrice: 13120,
        },
      },
      Telangana: {
        flat_bed: {
          price: 3.87,
          emiPrice: 12864,
        },
        full_body: {
          price: 4.31,
          emiPrice: 14565,
        },
        pickup: {
          price: 3.89,
          emiPrice: 12961,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 3.87,
          emiPrice: 12864,
        },
        full_body: {
          price: 4.31,
          emiPrice: 14565,
        },
        pickup: {
          price: 3.89,
          emiPrice: 12961,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 3.87,
          emiPrice: 12864,
        },
        full_body: {
          price: 4.31,
          emiPrice: 14565,
        },
        pickup: {
          price: 3.89,
          emiPrice: 12961,
        },
      },
      Others: {
        flat_bed: {
          price: 3.87,
          emiPrice: 12864,
        },
        full_body: {
          price: 4.31,
          emiPrice: 14565,
        },
        pickup: {
          price: 3.89,
          emiPrice: 12961,
        },
      },
    },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    load: "550 Kg",
    range: "90 kms",
    batteryCapacity: "8 kwh",
    batteryVoltage: "51.2 V",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "3.5 hrs",
    warrantyBattery: "3 years",
    chargingType: "Home charger",
    cabinDoors: "Yes",
    variants: "2",
    emiPrice: "11,000",
    keyspecs: ["Powerful Pickup", "Enhanced Motor"],
    topSpeed: "50 km/h",
    price: "₹3,12,214",
    cost: "40 paise/ Km",
    bodyType: "Pickup Van, Delivery Van",

    brand: "Piaggio",

    remaining: "4",
    loadInfo: "For heavy load",
    galleryViewImages: [
      {
        id: 1,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_1.webp`,
      },
      {
        id: 2,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_2.webp`,
      },
      {
        id: 3,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_3.webp`,
      },
      {
        id: 4,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_4.webp`,
      },

      {
        id: 6,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_6.webp`,
      },
      {
        id: 7,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_7.webp`,
      },
      {
        id: 8,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_8.webp`,
      },
      {
        id: 9,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_9.webp`,
      },
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_10.webp`,
      },
      {
        id: 11,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_11.webp`,
      },

      {
        id: 13,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_13.webp`,
      },

      {
        id: 15,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/Piaggio_Gallery/piaggio_15.webp`,
      },
    ],
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,
    galleryIcon:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/flipIcons/PiaggioApeE-XtraFXMax.webp`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_rage_plus.webp`,
    vehicle_logo:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/piaggio_logo.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/png/piaggio_logo.png`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/PiaggioApeE-XtraFXMax.webp`,
        // browserType === "Chrome"
        //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m1.avif`
        //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/PiaggioApeE-XtraFXMax.webp`,
      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m1.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m2.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m2.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m3.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m4.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m4.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m5.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m5.webp`,
      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m6.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m6.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m7.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m8.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m8.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m9.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m9.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi there!👋 I am interested in purchasing Piaggio Ape Fx Max for my business 🚚!`,
    ProductImages: [
      {
        id: 1,
        type: "pickup",
        thumbnailTitle: "Pickup",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/pickup/Piaggiomaxpickup0.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/pickup/Piaggiomaxpickup1.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/pickup/Piaggiomaxpickup2.webp`,
          },
          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/pickup/Piaggiomaxpickup3.webp`,
          },
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/pickup/Piaggiomaxpickup4.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/pickup/mobile/Piaggiomaxpickup0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/pickup/mobile/Piaggiomaxpickup1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/pickup/mobile/Piaggiomaxpickup2.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/pickup/mobile/Piaggiomaxpickup3.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/pickup/mobile/Piaggiomaxpickup4.webp`,
          },
        ],
      },
      {
        id: 2,
        type: "flat_bed",
        thumbnailTitle: "Flat Bed",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
        imagesDesktop: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/flatbed/Piaggiomaxfletbed0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/flatbed/Piaggiomaxfletbed1.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/flatbed/mobile/Piaggiomaxfletbed0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/flatbed/mobile/Piaggiomaxfletbed1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/flatbed/mobile/Piaggiomaxfletbed2.webp`,
          },
        ],
      },
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/fullbody/PiaggiomaxFullbody0.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/fullbody/PiaggiomaxFullbody1.webp`,
          },
          {
            id: 19,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/fullbody/PiaggiomaxFullbody2.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 10,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/fullbody/mobile/PiaggiomaxFullbody0.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/fullbody/mobile/PiaggiomaxFullbody1.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/fullbody/mobile/PiaggiomaxFullbody2.webp`,
          },
        ],
      },
    ],
    testimonials: {
      customer_1: {
        id: 1,
        name: "Kamble",
        work: "Cooked Food Distribution",
        city: " Bangalore city",
        description:
          "I didn’t know which gaadi to buy for my business. Turno helped me.",
        description_name: "- Kamble, Cooked Food Distribution",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },

  // 9

  {
    id: 9,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Mahindra Zor Grand",
    vehicle_name: "Zor Grand",
    vehicle_path_id: "Zor-Grand",
    imageAltNameGallery: "Mahindra-Zor-Grand-Commercial-Auto",
    imageAltName360: "Mahindra-Zor-Grand-Commercial-Auto",
    businessId: ["fish", "cylinder", "ecommerce"],
    businessImage: {
      ecommerce: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/treoZorGrand/ecoomerce.webp`,
      fish: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/treoZorGrand/fish.webp`,
      cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/treoZorGrand/cylinder.webp`,
    },
    vehicle_id: "1",
    h1: "Mahindra Zor Grand: Key Specs, Top Speed, & Price Info -Turno",
    page_title: "Mahindra Zor Grand",
    meta_description:
      "Learn about the Mahindra Zor Grand, an electric three-wheeler! Get key features, top speed, and price information about this electric vehicle from Turno!",
    rating: 5,
    priceInLakhs: "4.3",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/MahindraTreoZorGrand.webp`,
    homeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/MahindraTreoZorGrand.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/MahindraTreoZorGrand.webp`,
    newLaunchImage: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/treoNewLaunch.webp`,
    storeImageAltName: "Mahindra Zor Grand",
    vehicleId: 9,
    deal: imagesWEBP?.goodDeal,
    prices: {
      Karnataka: {
        flat_bed: {
          price: 3.98,
          emiPrice: 13292,
        },
        full_body: {
          price: 4.36,
          emiPrice: 14754,
        },
        pickup: {
          price: 4.3,
          emiPrice: 14525,
        },
      },
      "Tamil Nadu": {
        flat_bed: {
          price: 3.76,
          emiPrice: 13631,
        },
        full_body: {
          price: 4.13,
          emiPrice: 13850,
        },
        pickup: {
          Price: 4.07,
          emiPrice: 13631,
        },
      },
      Telangana: {
        flat_bed: {
          price: 3.76,
          emiPrice: 12457,
        },
        full_body: {
          price: 4.13,
          emiPrice: 13850,
        },
        pickup: {
          price: 4.06,
          emiPrice: 13631,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 3.98,
          emiPrice: 13297,
        },
        full_body: {
          price: 4.35,
          emiPrice: 14727,
        },
        pickup: {
          price: 4.29,
          emiPrice: 14502,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 3.98,
          emiPrice: 13297,
        },
        full_body: {
          price: 4.35,
          emiPrice: 14727,
        },
        pickup: {
          price: 4.29,
          emiPrice: 14502,
        },
      },
      Others: {
        flat_bed: {
          price: 3.98,
          emiPrice: 13292,
        },
        full_body: {
          price: 4.36,
          emiPrice: 14754,
        },
        pickup: {
          price: 4.3,
          emiPrice: 14525,
        },
      },
    },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    load: "550 Kg",
    range: "85 kms",
    batteryCapacity: "10.24 kwh",
    batteryVoltage: "48 V",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "4 hrs",
    warrantyBattery: "3 years",
    chargingType: "Home charger",
    cabinDoors: "Yes",
    variants: "3",
    emiPrice: "14,754",
    keyspecs: ["Clutch Free Gearless Drive", "Excellent Torque"],
    topSpeed: "50 km/h",
    price: "₹4,36,325",
    cost: "40 paise/ Km",
    bodyType: "Flat bed, Pickup , Delivery Van",
    brand: "Mahindra",
    remaining: "4",
    loadInfo: "For heavy load",

    galleryViewImages: [
      {
        id: 1,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/ZorGrand1.webp`,
        alt: "Mahindra Zor Grand",
      },
      {
        id: 2,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/ZorGrand2.webp`,
        alt: "Mahindra electric pickups",
      },
      {
        id: 3,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/ZorGrand3.webp`,
        alt: "Mahindra Treo Zor grand auto",
      },
      {
        id: 4,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/ZorGrand4.webp`,
        alt: "Mahindra Zor Grand Trucks",
      },
      {
        id: 5,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/ZorGrand5.webp`,
        alt: "Mahindra goods carrier",
      },
    ],
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,
    galleryIcon:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/flipIcons/+MahindraTreoZorGrand.webp`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_rage_plus.webp`,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahidra_logo.webp`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/MahindraTreoZorGrand.webp`,
      },
      // {
      //   id: 11,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m2.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m2.webp`,
      // },
      // {
      //   id: 12,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m3.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,
      // },
      // {
      //   id: 13,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m4.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m4.webp`,
      // },
      // {
      //   id: 14,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m5.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m5.webp`,
      // },
      // {
      //   id: 15,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m6.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m6.webp`,
      // },
      // {
      //   id: 16,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m7.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
      // },
      // {
      //   id: 17,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m8.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m8.webp`,
      // },
      // {
      //   id: 18,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m9.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m9.webp`,
      // },
      // {
      //   id: 7,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
      // },
      // {
      //   id: 9,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_pickup.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_pickup.jpg`,
      // },
      // {
      //   id: 8,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_full_body.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_full_body.jpg`,
      // },
    ],

    ProductImages: [
      {
        id: 1,
        type: "pickup",
        thumbnailTitle: "Pickup",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/pickup/Mahindrazrandpickup0.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/pickup/Mahindrazrandpickup1.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/pickup/Mahindrazrandpickup2.webp`,
          },
          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/pickup/Mahindrazrandpickup3.webp`,
          },
          // {
          //   id: 17,
          //   src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/pickup/Mahindrazrandpickup4.webp`,
          // },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/pickup/mobile/Mahindrazrandpickup0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/pickup/mobile/Mahindrazrandpickup1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/pickup/mobile/Mahindrazrandpickup2.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/pickup/mobile/Mahindrazrandpickup3.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/pickup/mobile/Mahindrazrandpickup4.webp`,
          },

          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/pickup/mobile/Mahindrazrandpickup5.webp`,
          },
        ],
      },
      // {
      //   id: 2,
      //   type: "flat_bed",
      //   thumbnailTitle: "Flat Bed",
      //   thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,
      //   imagesDesktop: [
      //     {
      //       id: 11,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/flatbed/MahindratreoFletbed.webp`,
      //     },
      //     {
      //       id: 12,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/flatbed/MahindratreoFletbed1.webp`,
      //     },
      //   ],

      //   imagesMobile: [
      //     {
      //       id: 11,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/flatbed/mobile/MahindratreoFletbed.webp`,
      //     },
      //     {
      //       id: 12,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/flatbed/mobile/MahindratreoFletbed1.webp`,
      //     },
      //     {
      //       id: 13,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/flatbed/mobile/MahindratreoFletbed2.webp`,
      //     },
      //   ],
      // },
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/fullbody/MahindrazrandFullbody0.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/fullbody/MahindrazrandFullbody1.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 10,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/fullbody/mobile/MahindrazrandFullbody0.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/fullbody/mobile/MahindrazrandFullbody1.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/fullbody/mobile/MahindrazrandFullbody2.webp`,
          },
        ],
      },
    ],

    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/MahindraTreoZorGrand.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi, I'm interested in purchasing a Mahindra Zor Grand EV for my business!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Naveen",
        work: "WaterCan Distributor",
        city: " Bangalore city",
        description:
          "Getting a gaadi through Turno was easy and quick. Documentation in just 5 days!",
        description_name: "- Naveen, Water Can Distribution",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },

  // EUler new
  {
    id: 1,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Euler HiLoad 170 Cu. Ft",
    vehicle_name: "HiLoad DV 170 Cu. Ft",
    vehicle_path_id: "HiLoadDV170",
    imageAltNameGallery: "Euler HiLoad Commercial Electric Gadi",
    imageAltName360: "Euler HiLoad Commercial Electric Gadi 360",
    businessId: ["cylinder", "vegetableFruits"],
    businessImage: {
      cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/gas_cylinders.webp`,
      vegetableFruits: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/vegetables.webp`,
    },
    vehicle_id: "6",
    h1: "Euler HiLoad EV: Price & features | Electric three wheeler- Turno",
    meta_description:
      "Learn about the Euler HiLoad EV, an electric three-wheeler, and its price and features from Turno. Book your test drive now!",
    rating: 4.6,
    priceInLakhs: "4.51",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV170.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV170.webp`,
    storeImageAltName: "Euler HiLoad 170 Cu. Ft",
    vehicleId: 10,
    deal: imagesWEBP?.bestPrice,
    prices: {
      Karnataka: {
        full_body: {
          price: 4.56,
          emiPrice: 15524,
        },
      },
      "Tamil Nadu": {
        full_body: {
          price: 4.56,
          emiPrice: 15501,
        },
      },
      Telangana: {
        full_body: {
          price: 4.5,
          emiPrice: 15269,
        },
      },
      Maharashtra: {
        full_body: {
          price: 4.5,
          emiPrice: 15269,
        },
      },
      "Uttar Pradesh": {
        full_body: {
          price: 4.5,
          emiPrice: 15269,
        },
      },
      Others: {
        full_body: {
          price: 4.51,
          emiPrice: 12386,
        },
      },
    },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    load: "688 Kg",
    range: "120 kms",
    batteryCapacity: "13 kwh",
    batteryVoltage: "72 V",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "3.5-4 hrs",
    warrantyBattery: "3 years",
    chargingType: "Portable charger",
    cabinDoors: "Yes",
    variants: "3",
    emiPrice: "15,524",
    keyspecs: ["Fast Charging", "Disc Brakes"],
    topSpeed: "45 km/h",
    price: "₹4,56,499",
    cost: "40 paise/ Km",
    bodyType: "Flat bed, Pickup Van, Delivery Van",
    brand: "Euler",
    remaining: "4",
    loadInfo: "For heavy load",

    ProductImages: [
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/Eulerfullbody0.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/Eulerfullbody1.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 10,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/mobile/Eulerfullbody0.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/mobile/Eulerfullbody1.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/mobile/Eulerfullbody2.webp`,
          },
        ],
      },
    ],
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,

    vehicle_logo:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/euler_logo.webp`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/png/mahindra_logo.png`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    galleryIcon:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/flipIcons/EulerEulerHiLoad.webp`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_rage_plus.webp`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerEulerHiLoadHD.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerEulerHiLoadHD.webp`,
    ],
    link: "https://wa.me/+919900852233?text=Hi there!👋 I am interested in purchasing Euler Hiload for my business 🚚!",
    testimonials: {
      customer_1: {
        id: 1,
        name: "Parthasarathi",
        work: "Garment Business",
        city: " Bangalore city",
        description:
          "Turno's representatives even visited my office to collect the necessary documents for the EV loan.",
        description_name: "- Parthasarathi, Garment Business",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },

  //euler new 2
  {
    id: 1,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "HiLoad PV",
    vehicle_name: "HiLoad PV",
    vehicle_path_id: "HiLoadPV",
    imageAltNameGallery: "Euler HiLoad Commercial Electric Gadi",
    imageAltName360: "Euler HiLoad Commercial Electric Gadi 360",
    vehicle_id: "6",
    h1: "Euler HiLoad EV: Price & features | Electric three wheeler- Turno",
    meta_description:
      "Learn about the Euler HiLoad EV, an electric three-wheeler, and its price and features from Turno. Book your test drive now!",
    rating: 4.6,
    priceInLakhs: "4.14",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Euler+HiLoad+PV.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Euler+HiLoad+PV.webp`,
    storeImageAltName: "Euler Hiload PV",
    vehicleId: 11,
    deal: imagesWEBP?.goodDeal,
    prices: {
      Karnataka: {
        full_body: {
          price: 4.56,
          emiPrice: 15524,
        },
      },
      "Tamil Nadu": {
        full_body: {
          price: 4.56,
          emiPrice: 15501,
        },
      },
      Telangana: {
        full_body: {
          price: 4.5,
          emiPrice: 15269,
        },
      },
      Maharashtra: {
        full_body: {
          price: 4.5,
          emiPrice: 15269,
        },
      },
      Others: {
        full_body: {
          price: 4.14,
          emiPrice: 11911,
        },
      },
    },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    load: "688 Kg",
    range: "120 kms",
    batteryCapacity: "13 kwh",
    batteryVoltage: "72 V",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "3.5-4 hrs",
    warrantyBattery: "3 years",
    chargingType: "Portable charger",
    cabinDoors: "Yes",
    variants: "3",
    emiPrice: "15,524",
    keyspecs: ["Fast Charging", "Disc Brakes"],
    topSpeed: "45 km/h",
    price: "₹4,56,499",
    cost: "40 paise/ Km",
    bodyType: "Flat bed, Pickup Van, Delivery Van",
    brand: "Euler",
    remaining: "4",
    loadInfo: "For heavy load",

    ProductImages: [
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/Eulerfullbody0.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/Eulerfullbody1.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 10,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/mobile/Eulerfullbody0.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/mobile/Eulerfullbody1.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/fullbody/mobile/Eulerfullbody2.webp`,
          },
        ],
      },
    ],
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,

    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/euler_logo.webp`,
    // browserType === "Chrome"
    //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/euler_logo.webp`
    //   : `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/png/mahindra_logo.png`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    galleryIcon:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/flipIcons/EulerEulerHiLoad.webp`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_rage_plus.webp`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Euler+HiLoad+PV.webp`,
        // browserType === "Chrome"
        //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m1.avif`
        //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Euler+HiLoad+PV.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi there!👋 I am interested in purchasing Euler Hiload for my business 🚚!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Parthasarathi",
        work: "Garment Business",
        city: " Bangalore city",
        description:
          "Turno's representatives even visited my office to collect the necessary documents for the EV loan.",
        description_name: "- Parthasarathi, Garment Business",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },

  {
    id: 12,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Altigreen",
    vehicle_name: "neEV",
    vehicle_path_id: "neEV",
    imageAltNameGallery: "Altigreen-NeEV-Electric-Cargo-Vehicle",
    imageAltName360: "Altigreen-NeEV-Electric-Cargo-Vehicle-360",
    businessId: ["fish", "ecommerce"],
    businessImage: {
      fish: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/altigreenNeev/altigreen_fishes.webp`,
      ecommerce: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/altigreenNeev/altigreen_cartons.webp`,
    },
    vehicle_id: "1",
    h1: "Altigreen neEV by Turno | Know more about Altigreen neEV | Altigreen neEV Service FAQ",
    page_title: "Get the best price for Altigreen neEV electric cargo vehicle",
    meta_description:
      "Buy Altigreen neEV from turno.club. Book a test drive today and get the best offer on Altigreen neEV electric cargo vehicle. Turno provides the best price and EV loan financing for electric commercial vehicle.",
    rating: 5,
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Altigreenhome.webp`,
    homeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Altigreenhome.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Altigreenhome.webp`,
    storeImageAltName: "Altigreen neEV",
    priceInLakhs: "4.26",
    vehicleId: 12,
    deal: imagesWEBP?.bestDeals,
    prices: {
      Karnataka: {
        // low_deck: {
        //   price: 4.26,
        //   emiPrice: 14369,
        // },
        // high_deck_141cuft: {
        //   price: 4.51,
        //   emiPrice: 15319,
        // },
        full_body: {
          price: 4.56,
          emiPrice: 15497,
        },
        pickup: {
          price: 4.26,
          emiPrice: 14369,
        },
      },
      Tamilnadu: {
        // low_deck: {
        //   price: 4.32,
        //   emiPrice: 14594,
        // },
        // high_deck_141cuft: {
        //   price: 4.56,
        //   emiPrice: 15528,
        // },
        full_body: {
          price: 4.61,
          emiPrice: 15695,
        },
        pickup: {
          price: 4.32,
          emiPrice: 14594,
        },
      },

      Telangana: {
        // low_deck: {
        //   price: 4.28,
        //   emiPrice: 14437,
        // },
        // high_deck_141cuft: {
        //   price: 4.53,
        //   emiPrice: 15380,
        // },
        full_body: {
          price: 4.57,
          emiPrice: 15545,
        },
        pickup: {
          price: 4.28,
          emiPrice: 14437,
        },
      },
      Maharashtra: {
        // low_deck: {
        //   price: 4.26,
        //   emiPrice: 14369,
        // },
        // high_deck_141cuft: {
        //   price: 4.51,
        //   emiPrice: 15319,
        // },
        full_body: {
          price: 4.56,
          emiPrice: 15497,
        },
        pickup: {
          price: 4.26,
          emiPrice: 14369,
        },
      },
      "Uttar Pradesh": {
        // low_deck: {
        //   price: 4.26,
        //   emiPrice: 14369,
        // },
        // high_deck_141cuft: {
        //   price: 4.51,
        //   emiPrice: 15319,
        // },
        full_body: {
          price: 4.56,
          emiPrice: 15497,
        },
        pickup: {
          price: 4.26,
          emiPrice: 14369,
        },
      },
      Others: {
        // low_deck: {
        //   price: 4.26,
        //   emiPrice: 14369,
        // },
        // high_deck_141cuft: {
        //   price: 4.51,
        //   emiPrice: 15319,
        // },
        full_body: {
          price: 4.56,
          emiPrice: 15497,
        },
        pickup: {
          price: 4.26,
          emiPrice: 14369,
        },
      },
    },

    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    load: "550 Kg",
    range: "120 kms",
    batteryCapacity: "11 kwh",
    batteryVoltage: "51.2 V",
    chargingUnits: "7.2",
    chargingCost: "64.8",
    chargingtime: "3.5 hrs",
    warrantyBattery: "3 years",
    chargingType: "Home charger",
    cabinDoors: "No",
    variants: "3",
    emiPrice: "14,369",
    keyspecs: ["Direct Drive Mode", "Low Noise"],
    topSpeed: "53 km/h",
    price: "₹4,26,244",
    cost: "92 paise/ Km",
    bodyType: "Low Deck, High Deck 141cu.ft,, High Deck 177cu.ft",
    brand: "Altigreen",
    remaining: "4",
    loadInfo: "For heavy load",
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,

    galleryIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreengalery.webp`,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreenlogo.webp`,

    image: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Altigreenpickup3.webp`,

    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Altigreenpickup3.webp`,
      },
      {
        id: 11,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Altigreenpickup2.webp`,
      },
      {
        id: 12,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Altigreenpickup3.webp`,
      },
      {
        id: 13,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Altigreenfletbed.webp`,
      },
      {
        id: 14,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Altigreenfullbody.webp`,
      },
      // {
      //   id: 15,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m6.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m6.webp`,
      // },
      // {
      //   id: 16,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m7.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
      // },
      // {
      //   id: 17,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m8.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m8.webp`,
      // },
      // {
      //   id: 18,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m9.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m9.webp`,
      // },
      // {
      //   id: 7,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
      // },
      // {
      //   id: 9,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_pickup.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_pickup.jpg`,
      // },
      // {
      //   id: 8,
      //   src:
      //     browserType === "Chrome"
      //       ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_full_body.avif`
      //       : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_full_body.jpg`,
      // },
    ],

    ProductImages: [
      {
        id: 1,
        type: "pickup",
        thumbnailTitle: "Pickup",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/pickup/NeevPickup0.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/pickup/NeevPickup1.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/pickup/NeevPickup2.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/pickup/mobile/NeevPickup0.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/pickup/mobile/NeevPickup1.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/pickup/mobile/NeevPickup2.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/pickup/mobile/NeevPickup3.webp`,
          },
        ],
      },
      // {
      //   id: 2,
      //   type: "flat_bed",
      //   thumbnailTitle: "Flat Bed",
      //   thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,
      //   thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
      //   thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
      //   imagesDesktop: [
      //     {
      //       id: 11,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/Greavesfletbed0.webp`,
      //     },
      //     {
      //       id: 12,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/Greavesfletbed1.webp`,
      //     },
      //     {
      //       id: 12,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/Greavesfletbed2.webp`,
      //     },
      //   ],

      //   imagesMobile: [
      //     {
      //       id: 11,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/mobile/Greavesfletbed0.webp`,
      //     },
      //     {
      //       id: 12,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/mobile/Greavesfletbed1.webp`,
      //     },
      //     {
      //       id: 13,
      //       src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/flatbed/mobile/Greavesfletbed2.webp`,
      //     },
      //   ],
      // },
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/fullbody/NeevFullBody1.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/fullbody/NeevFullBody0.webp`,
          },
        ],

        imagesMobile: [
          {
            id: 10,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/fullbody/mobile/NeevFullBody2.webp`,
          },
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/fullbody/mobile/NeevFullBody1.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/fullbody/mobile/NeevFullBody0.webp`,
          },
        ],
      },
    ],
    view360Images: [
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m1.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m1.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m2.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m2.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m3.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m3.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m4.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m4.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m5.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m5.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m6.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m6.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m7.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m8.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m8.webp`,

      // browserType === "Chrome"
      //   ? `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/avif/m9.avif`
      //   : `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m9.webp`,
    ],
    link: "https://wa.me/+919900852233?text=Hi, I'm interested in purchasing a Mahindra Treo Zor EV for my business!",
    testimonials: {
      customer_1: {
        id: 1,
        name: "Girish",
        work: "Logistics",
        city: " Bangalore city",
        description:
          "I really liked Turno's fast loan process.I got my EV in 6 days",
        description_name: "- Girish L, Logistics",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },
  {
    id: 16,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Montra ePV 2.0",
    vehicle_name: "Electric Super Auto",
    vehicle_path_id: "Montra-Super-Auto",
    imageAltNameGallery: "Montra Electric Super Auto",
    imageAltName360: "Montra Electric Super Auto 360",
    businessId: ["cylinder", "vegetableFruits"],
    type: "passenger",
    peakTorque: "60 Nm",
    businessImage: {
      cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/gas_cylinders.webp`,
      vegetableFruits: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/vegetables.webp`,
    },
    vehicle_id: "16",
    h1: "Montra Electric Super Auto: Price & features | Electric three wheeler- Turno",
    meta_description:
      "Explore Montra passenger Electric Auto Price, features, test drives, EMI options & battery capacity. Buy Montra EV from Turno today at the best prices",
    rating: 4.6,
    priceInLakhs: "4.14",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
    storeImageAltName: "Montra Electric Super Auto",
    vehicleId: 5,
    deal: imagesWEBP?.bestPrice,
    prices: {
      Karnataka: {
        full_body: {
          price: 3.76,
          emiPrice: 14515,
        },
      },
      "Tamil Nadu": {
        full_body: {
          price: 3.76,
          emiPrice: 14515,
        },
      },
      Telangana: {
        full_body: {
          price: 3.76,
          emiPrice: 14515,
        },
      },
      Maharashtra: {
        full_body: {
          price: 3.76,
          emiPrice: 14515,
        },
      },
      "Uttar Pradesh": {
        full_body: {
          price: 3.76,
          emiPrice: 14515,
        },
      },
      Others: {
        full_body: {
          price: 3.76,
          emiPrice: 14515,
        },
      },
    },

    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    seatingCapacity: "D+3",
    range: "160 km",
    batteryVoltage: "48 V",
    batteryCapacity: "10 kwh",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "4 hrs",
    warrantyBattery: "5 years",
    chargingType: "Portable charger",
    cabinDoors: "Yes",
    variants: "3",
    emiPrice: "15,321",
    keyspecs: ["Superior Range", "Regenerative Braking"],
    topSpeed: "55 km/h",
    price: "₹3,76,498",
    cost: "40 paise/ Km",
    costPer100Km: "₹ 40",
    bodyType: "",
    brand: "Montra",
    remaining: "4",
    loadInfo: "Superior Range and Comfort",
    ProductImages: [
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/montra-desk-1.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/montra-desk-2.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/montra-desk-3.webp`,
          },
          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/montra-desk-4.webp`,
          },
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/montra-desk-5.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/montra-desk-6.webp`,
          },
          {
            id: 19,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/montra-desk-8.webp`,
          },
          {
            id: 20,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/montra-desk-9.webp`,
          },
        ],
        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/mobile/montra-mob-1.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/mobile/montra-mob-2.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/mobile/montra-mob-3.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/mobile/montra-mob-4.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/mobile/montra-mob-5.webp`,
          },
          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/mobile/montra-mob-6.webp`,
          },
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/mobile/montra-mob-7.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/mobile/montra-mob-8.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/fullBody/mobile/montra-mob-9.webp`,
          },
        ],
      },
    ],
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,
    galleryIcon:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/flipIcons/EulerEulerHiLoad.webp`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_rage_plus.webp`,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/others/montra_logo.webp`,
    newLaunchImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/JustLaunched/main_banner_720_703_Montra.webp`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerEulerHiLoad.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi there!:wave:I am interested in purchasing Montra ePV 2.0 for my business :truck:!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Parthasarathi",
        work: "Garment Business",
        city: "Bangalore city",
        description:
          "Turno's representatives even visited my office to collect the necessary documents for the EV loan.",
        description_name: "- Parthasarathi, Garment Business",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },
  {
    id: 17,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Piaggio E-City FX",
    vehicle_name: "E-City Fx Auto",
    vehicle_path_id: "E-City-FX",
    imageAltNameGallery: "Piaggio E-City Fx Auto",
    imageAltName360: "Piaggio E-City Fx Auto 360",
    businessId: ["cylinder", "vegetableFruits"],
    businessImage: {
      cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/gas_cylinders.webp`,
      vegetableFruits: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/vegetables.webp`,
    },
    vehicle_id: "6",
    h1: "Piaggio E-City Fx Auto Auto: Price & features |Passenger Electric three wheeler- Turno",
    meta_description: "Explore Piaggio E-City Fx Auto Price, features, test drives, EMI options & battery capacity. Buy Piaggio E-City Fx EV from Turno today at the best prices",
    rating: 4.6,
    priceInLakhs: "3.2",
    homeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/CardImages/Piaggio+passenger+413x287.webp`,
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/CardImages/Piaggio+passenger+413x287.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/CardImages/Piaggio+passenger+413x287.webp`,
    storeImageAltName: "Piaggio E-City Fx Electric Auto",
    vehicleId: 5,
    deal: imagesWEBP?.bestPrice,
    prices: {
      Karnataka: {
        full_body: {
          price: 3.2,
          emiPrice: 10324,
        },
        pickup: {
          price: 3.2,
          emiPrice: 10324,
        },
      },
      "Tamil Nadu": {
        full_body: {
          price: 3.2,
          emiPrice: 10324,
        },
        pickup: {
          Price: 3.2,
          emiPrice: 10324,
        },
      },
      Telangana: {
        full_body: {
          price: 3.2,
          emiPrice: 10324,
        },
        pickup: {
          price: 3.2,
          emiPrice: 10324,
        },
      },
      Maharashtra: {
        full_body: {
          price: 3.2,
          emiPrice: 10324,
        },
        pickup: {
          price: 3.2,
          emiPrice: 10324,
        },
      },
      "Uttar Pradesh": {
        Karnataka: {
          full_body: {
            price: 3.2,
            emiPrice: 10324,
          },
          pickup: {
            price: 3.2,
            emiPrice: 10324,
          },
        },
      },
      Others: {
        full_body: {
          price: 3.2,
          emiPrice: 10324,
        },
        pickup: {
          price: 3.2,
          emiPrice: 10324,
        },
      },
    },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    seatingCapacity: "D+3",
    range: "110 kms",
    batteryVoltage: "48 V",
    batteryCapacity: "10 kwh",
    chargingUnits: "-",
    chargingCost: "-",
    type: "passenger",
    chargingtime: "3 hrs 45 mins",
    warrantyBattery: "3 years",
    chargingType: "Portable charger",
    cabinDoors: "Yes",
    variants: "3",
    emiPrice: "10,324",
    keyspecs: ["Superior Range", "Regenerative Braking"],
    topSpeed: "45 km/h",
    price: "",
    cost: "39 paise/ Km",
    costPer100Km: "₹ 39",
    bodyType: "",
    brand: "Piaggio",
    remaining: "4",
    loadInfo: "Superior Range and Comfort",
    ProductImages: [
      {
        id: 3,
        type: "full_body",
        thumbnailTitle: "Full Body",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/fullbody/piaggioEcityFx-desk-1.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/fullbody/piaggioEcityFx-desk-2.webp`,

          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/fullbody/piaggioEcityFx-desk-3.webp`,
          },
          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/fullbody/piaggioEcityFx-desk-4.webp`,
          }
        ],
        imagesMobile: [
          {
            id: 11,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/fullbody/mobile/piaggioEcityFx-mob-1.webp`,
          },
          {
            id: 12,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/fullbody/mobile/piaggioEcityFx-mob-2.webp`,
          },
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/fullbody/mobile/piaggioEcityFx-mob-3.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/fullbody/mobile/piaggioEcityFx-mob-4.webp`,
          },
        ],
      },
    ],
    vehiclePageImage: browserType === "Chrome"
      ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
      : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,
    galleryIcon: browserType === "Chrome"
      ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/CardImages/Piaggio+passenger+143x94.webp`
      : `${process.env.NEXT_PUBLIC_CDN_LINK}/v2/Vehicles/Piaggio-EcityFx/CardImages/Piaggio+passenger+143x94.webp`,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/piaggio_logo.avif`,
    newLaunchImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/JustLaunched/main_banner_720_703_Piaggio.webp`,
    galleryViewImages: [
      {
        id: 1,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/CardImages/carouselImages/Piaggio_Ecity_Fx_1.webp`,
      },
      {
        id: 2,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/CardImages/carouselImages/Piaggio_Ecity_Fx_2.webp`,
      },
      {
        id: 3,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/CardImages/carouselImages/Piaggio_Ecity_Fx_3.webp`,
      },
      {
        id: 4,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/CardImages/carouselImages/Piaggio_Ecity_Fx_4.webp`,
      }
    ],
    image: browserType === "Chrome"
      ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/CardImages/Piaggio+passenger+143x94.webp`
      : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerEulerHiLoad.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi there!:wave:I am interested in purchasing Piaggio E-City Fx for my business :truck:!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Parthasarathi",
        work: "Garment Business",
        city: "Bangalore city",
        description: "Turno's representatives even visited my office to collect the necessary documents for the EV loan.",
        description_name: "- Parthasarathi, Garment Business",
        img: browserType === "Chrome"
          ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
          : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
    brochure_url: "https://assets.turnoclub.com/developer/brouchers/apeEcityFx.pdf",
   },
   {
    id: 18,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Bajaj RE E TEC 9.0",
    vehicle_name: "RE E TEC 9.0",
    vehicle_path_id: "BajajRETEC",
    imageAltNameGallery: "Bajaj RE E TEC 9.0 Passenger Auto",
    imageAltName360: "Bajaj RE E TEC 9.0 Passenger Auto 360",
    businessId: ["Passenger", "Transport"],
    type: "passenger",
    peakTorque: "36 Nm",
    businessImage: {
      cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/gas_cylinders.webp`,
      vegetableFruits: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/vegetables.webp`,
    },    
    vehicle_id: "6",
    h1: "Bajaj RE E TEC 9.0 Passenger Auto Auto: Price & features | Electric three wheeler- Turno",
    meta_description: "Explore Bajaj RE E TEC 9.0 Passenger Auto Price, features, test drives, EMI options & battery capacity. Buy Bajaj RE E TEC 9.0 Passenger Auto from Turno today at the best prices",
    rating: 4.6,
    priceInLakhs: "3.65",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
    storeImageAltName: "Bajaj RE E TEC 9.0 Passenger Auto",
    vehicleId: 6,
    deal: imagesWEBP?.bestPrice,
    prices: {
      Karnataka: {
        soft_roof: {
          price: 3.65,
          emiPrice: 14000,
        },
         },
      "Tamil Nadu": {
        soft_roof: {
          price: 3.65,
          emiPrice: 14000,
          },
      },
      Telangana: {
        soft_roof: {
          price: 3.65,
          emiPrice: 14000,
        },
        },
      Maharashtra: {
        soft_roof: {
          price: 3.65,
          emiPrice: 14000,
        },
        },
      "Uttar Pradesh": {
        soft_roof: {
          price: 3.65,
          emiPrice: 14000,
        },
      },
      Others: {
       soft_roof: {
          price: 3.65,
          emiPrice: 14000,
        },
        },
      },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    seatingCapacity: "D+3",
    range: "178 km",
    batteryVoltage: "48 V",
    batteryCapacity: "8.9 kwh",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "4 hrs 30 mins",
    warrantyBattery: "5 years",
    chargingType: "Portable charger",
    cabinDoors: "Yes",
    variants: "3",
    emiPrice: "14,000",
    keyspecs: ["Superior Range", "Hill-hold assist"],
    topSpeed: "45 km/h",
    price: "₹3,65,000",
    cost: "40 paise/ Km",
    costPer100Km: "₹ 40",
    bodyType: "",
    brand: "Bajaj",
    remaining: "4",
    loadInfo: "Superior Range and Comfort",
    ProductImages: [
      {
        id: 3,
        type: "soft_roof",
        thumbnailTitle: "soft_roof",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Bajaj/fullbody/Desktop/bajaj_in_front_view_1888_1079.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Bajaj/fullbody/Desktop/bajaj_in_left_view_1888_1079.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Bajaj/fullbody/Desktop/bajaj_in_right_view_1888_1079.webp`,
          }
        ],
        imagesMobile: [
          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Bajaj/fullbody/mobile/bajaj_in_front_view_677_660.webp`,
          },
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Bajaj/fullbody/mobile/bajaj_in_left_view_677_660.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Bajaj/fullbody/mobile/bajaj_in_right_view_677_660.webp`,
          }
        ],
      },
    ],
    vehiclePageImage:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,
    galleryIcon:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/flipIcons/EulerEulerHiLoad.webp`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_rage_plus.webp`,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Bajaj/logo/bajaj_logo.webp`,
    newLaunchImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/JustLaunched/main_banner_720_703_bajaj.webp`,
    image:
      browserType === "Chrome"
        ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
        : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerEulerHiLoad.webp`,
    ],
    link: `https://wa.me/+${whatsappNumber}?text=Hi there!:wave:I am interested in purchasing Montra ePV 2.0 for my business :truck:!`,
    testimonials: {
      customer_1: {
        id: 1,
        name: "Parthasarathi",
        work: "Garment Business",
        city: "Bangalore city",
        description:
          "Turno's representatives even visited my office to collect the necessary documents for the EV loan.",
        description_name: "- Parthasarathi, Garment Business",
        img:
          browserType === "Chrome"
            ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
            : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
  },
  {
    id: 19,
    uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
    name: "Mahindra Treo Plus",
    vehicle_name: "Treo Plus Auto",
    vehicle_path_id: "MahindraTreoPlus",
    imageAltNameGallery: "Mahindra Treo Plus Passenger Auto",
    imageAltName360: "Mahindra Treo Plus Passenger Auto 360",
    businessId: ["Passenger", "Transport"],
    type: "passenger",
    businessImage: {
      cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/gas_cylinders.webp`,
      vegetableFruits: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/vegetables.webp`,
    },
    vehicle_id: "6",
    h1: "Mahindra Treo Plus Passenger Auto Auto: Price & features | Electric three wheeler- Turno",
    meta_description: "Explore Mahindra Treo Plus Passenger Auto Price, features, test drives, EMI options & battery capacity. Buy Mahindra Treo Plus Passenger Auto from Turno today at the best prices",
    rating: 4.6,
    priceInLakhs: "3.85",
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
    latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
    homeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindraTreoPlusVehicleCard/MahindraTreoPlusBanner.webp`,
    storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindraTreoPlusVehicleCard/MahindraTreoPlusBanner.webp`,
    storeImageAltName: "Mahindra Treo Plus Passenger Auto",
    vehicleId: 19,
    deal: imagesWEBP?.bestPrice,
    prices: {
      Karnataka: {
        soft_roof:{ 
          price: 3.85,
          emiPrice: 14000,
        },
      },
      "Tamil Nadu": {
        soft_roof: {
          price: 3.85,
          emiPrice: 14000,
          },
      },
      Telangana: {
        soft_roof: {
          price: 3.85,
          emiPrice: 14000,
        },
      },
      Maharashtra: {
        soft_roof: {
          price: 3.85,
          emiPrice: 14000,
        },
      },
      "Uttar Pradesh": {
        soft_roof: {
          price: 3.85,
          emiPrice: 14000,
        },
      },
      Others: {
       soft_roof: {
          price: 3.85,
          emiPrice: 14000,
        },
        full_body: {
          price: 3.85,
          emiPrice: 14000,
        }
      },
    },
    desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
    seatingCapacity: "D+3",
    range: "150 km",
    batteryVoltage: "48 V",
    batteryCapacity: "10.24 kwh",
    chargingUnits: "-",
    chargingCost: "-",
    chargingtime: "4 hrs 20 mins",
    warrantyBattery: "5 years",
    chargingType: "Portable charger",
    cabinDoors: "Yes",
    variants: "2",
    emiPrice: "14,000",
    keyspecs: ["Superior Range", "Hill-hold assist"],
    topSpeed: "55 km/h",
    price: "₹3,85,000",
    cost: "40 paise/ Km",
    costPer100Km: "₹ 40",
    bodyType: "Soft Roof Top, Hard Roof Top",
    brand: "Mahindra",
    remaining: "4",
    loadInfo: "Superior Range and Comfort",
    ProductImages: [
      {
        id: 3,
        type: "soft_roof",
        thumbnailTitle: "soft_roof",
        thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
        imagesDesktop: [
          {
            id: 13,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/MahindraTreoPlus/fullbody/Desktop/mahindraTreoMainBanner.webp`,
          },
          {
            id: 14,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/MahindraTreoPlus/fullbody/Desktop/MahindraTeroPlusmainBannerRightView.webp`,
          },
          {
            id: 15,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/MahindraTreoPlus/fullbody/Desktop/MahindraTeroPlusSideMainBanner.webp`,
          }
        ],
        imagesMobile: [
          {
            id: 16,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/MahindraTreoPlus/fullbody/mobile/mahindraTreoPlusLeftView.webp`,
          },
          {
            id: 17,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/MahindraTreoPlus/fullbody/mobile/mahindraTreoPlusRightView.webp`,
          },
          {
            id: 18,
            src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/MahindraTreoPlus/fullbody/mobile/MahindraTreoPlusSideView.webp`,
          }
        ],
      },
    ],
    vehiclePageImage: browserType === "Chrome"
      ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
      : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,
    galleryIcon: browserType === "Chrome"
      ? `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindraTreoPlusVehicleCard/MahindraTreoPlusCard.webp`
      : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_rage_plus.webp`,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahidra_logo.webp`,
    image: browserType === "Chrome"
      ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
      : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
    newLaunchImage: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/JustLaunched/mahindraTreoPlusLeftView.webp`,
    galleryViewImages: [{
      id: 1,
      src:`${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindraTreoPlusVehicleCard/carouselImages/mahindraTreoPlus_1.webp`
    },{
      id: 2,
      src:`${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindraTreoPlusVehicleCard/carouselImages/mahindraTreoPlus_2.webp`
    },{
      id: 3,
      src:`${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindraTreoPlusVehicleCard/carouselImages/mahindraTreoPlus_3.webp`
    },{
      id: 4,
      src:`${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindraTreoPlusVehicleCard/carouselImages/mahindraTreoPlus_4.webp`
    }],
    images: [
      {
        id: 10,
        src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
      },
    ],
    view360Images: [
      `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerEulerHiLoad.webp`,
    ],
    brochure_url: "https://assets.turnoclub.com/developer/brouchers/MahindraTreoPlusBroucher.pdf",
    link: "https://wa.me/+918867392233?text=Hi there!:wave:I am interested in purchasing Montra ePV 2.0 for my business :truck:!",
    testimonials: {
      customer_1: {
        id: 1,
        name: "Parthasarathi",
        work: "Garment Business",
        city: "Bangalore city",
        description: "Turno's representatives even visited my office to collect the necessary documents for the EV loan.",
        description_name: "- Parthasarathi, Garment Business",
        img: browserType === "Chrome"
          ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
          : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
        videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
      },
    },
    },
    {
      id: 20,
      uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
      name: "Storm Ev",
      vehicle_name: "Storm EV",
      vehicle_path_id: "Storm-Ev",
      imageAltNameGallery: "Montra Electric Super Auto",
      imageAltName360: "Montra Electric Super Auto 360",
      businessId: ["cylinder", "vegetableFruits"],
      type: "cargo4wheeler",
      peakTorque: "60 Nm",
      businessImage: {
        cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/gas_cylinders.webp`,
        vegetableFruits: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/vegetables.webp`,
      },
      vehicle_id: "16",
      h1: "Montra Electric Super Auto: Price & features | Electric three wheeler- Turno",
      meta_description:
        "Explore Montra passenger Electric Auto Price, features, test drives, EMI options & battery capacity. Buy Montra EV from Turno today at the best prices",
      rating: 4.6,
      priceInLakhs: "4.14",
      storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/stormEvBanner1.webp`,
      latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/stormEvBanner1.webp`,
      storeImageAltName: "Montra Electric Super Auto",
      vehicleId: 5,
      deal: imagesWEBP?.bestPrice,
      prices: {
        Karnataka: {
          full_body: {
            price: 9.1,
            emiPrice: 19000,
          },
        },
        "Tamil Nadu": {
          full_body: {
            price: 9.1,
            emiPrice: 19000,
          },
        },
        Telangana: {
          full_body: {
            price: 9.1,
            emiPrice: 19000,
          },
        },
        Maharashtra: {
          full_body: {
            price: 9.1,
            emiPrice: 19000,
          },
        },
        "Uttar Pradesh": {
          full_body: {
            price: 9.1,
            emiPrice: 19000,
          },
        },
        Others: {
          full_body: {
            price: 9.1,
            emiPrice: 19000,
          },
        },
      },
  
      desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
      seatingCapacity: "D+3",
      range: "140 km",
      load:"1250 kg",
      batteryVoltage: "48 V",
      batteryCapacity: "10 kwh",
      chargingUnits: "-",
      chargingCost: "-",
      chargingtime: "4 hrs",
      warrantyBattery: "5 years",
      chargingType: "Portable charger",
      cabinDoors: "Yes",
      variants: "3",
      emiPrice: "15,321",
      keyspecs: ["Superior Range", "Regenerative Braking"],
      topSpeed: "70 km/h",
      price: "₹3,76,498",
      cost: "40 paise/ Km",
      costPer100Km: "₹ 40",
      bodyType: "",
      brand: "Euler",
      remaining: "4",
      loadInfo: "Superior Range and Comfort",
      ProductImages: [
        {
          id: 3,
          type: "full_body",
          thumbnailTitle: "Full Body",
          thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
          thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
          thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
          imagesDesktop: [
            {
              id: 13,
              src: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/stormEvDesk1.webp`,
            },
            {
              id: 14,
              src: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/stormEvDesk2.webp`,
            }
          ],
          imagesMobile: [
            {
              id: 11,
              src: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/stormEv1.webp`,
            },
          
          ],
        },
      ],
      vehiclePageImage:
        browserType === "Chrome"
          ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
          : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,
      galleryIcon:
        browserType === "Chrome"
          ? `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/storm143.webp`
          : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_rage_plus.webp`,
      vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/euler_logo.webp`,
      newLaunchImage: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/euler1.webp`,
      image:
        browserType === "Chrome"
          ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
          : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
      images: [
        {
          id: 10,
          src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
        },
      ],
      view360Images: [
        `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerEulerHiLoad.webp`,
      ],
      link: `https://wa.me/+${whatsappNumber}?text=Hi there!:wave:I am interested in purchasing Montra ePV 2.0 for my business :truck:!`,
      testimonials: {
        customer_1: {
          id: 1,
          name: "Parthasarathi",
          work: "Garment Business",
          city: "Bangalore city",
          description:
            "Turno's representatives even visited my office to collect the necessary documents for the EV loan.",
          description_name: "- Parthasarathi, Garment Business",
          img:
            browserType === "Chrome"
              ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
              : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
          videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
        },
      },
    },
    {
      id: 21,
      uuid: "5ce9a4af-a783-4dba-a658-72869dd80f0f",
      name: "EKA K1.5",
      vehicle_name: "K1.5 Mini Truck",
      vehicle_path_id: "K1.5",
      imageAltNameGallery: "Montra Electric Super Auto",
      imageAltName360: "Montra Electric Super Auto 360",
      businessId: ["cylinder", "vegetableFruits"],
      type: "cargo4wheeler",
      peakTorque: "220 Nm",
      businessImage: {
        cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/gas_cylinders.webp`,
        vegetableFruits: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/eulerHiload/vegetables.webp`,
      },
      vehicle_id: "16",
      h1: "Montra Electric Super Auto: Price & features | Electric three wheeler- Turno",
      meta_description:
        "Explore Montra passenger Electric Auto Price, features, test drives, EMI options & battery capacity. Buy Montra EV from Turno today at the best prices",
      rating: 4.6,
      priceInLakhs: "4.14",
      storeImage: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/ekaBanner1.webp`,
      latestModel: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/ekaBanner1.webp`,
      storeImageAltName: "Montra Electric Super Auto",
      vehicleId: 5,
      deal: imagesWEBP?.bestPrice,
      prices: {
        Karnataka: {
          full_body: {
            price: 16.36,
            emiPrice: "35,000",
          },
        },
        "Tamil Nadu": {
          full_body: {
            price: 16.36,
            emiPrice: "35,000",
          },
        },
        Telangana: {
          full_body: {
            price: 16.36,
            emiPrice: "35,000",
          },
        },
        Maharashtra: {
          full_body: {
            price: 16.36,
            emiPrice: "35,000",
          },
        },
        "Uttar Pradesh": {
          full_body: {
            price: 16.36,
            emiPrice: "35,000",
          },
        },
        Others: {
          full_body: {
            price: 16.36,
            emiPrice: "35,000",
          },
        },
      },
  
      desc: "vehicle-page-trustable-and-spacious-vehicle-with-easy-entry-and-exit",
      seatingCapacity: "D+3",
      range: "180 km",
      load:'1500 kg',
      batteryVoltage: "48 V",
      batteryCapacity: "32 kwh",
      chargingUnits: "-",
      chargingCost: "-",
      chargingtime: "4 hrs",
      warrantyBattery: "5 years",
      chargingType: "Portable charger",
      cabinDoors: "Yes",
      variants: "3",
      emiPrice: "UPCOMING",
      keyspecs: ["Superior Range", "Regenerative Braking"],
      topSpeed: "70 km/h",
      price: "₹16,36,498",
      cost: "40 paise/ Km",
      costPer100Km: "₹ 40",
      bodyType: "",
      brand: "Eka",
      remaining: "4",
      loadInfo: "Superior Range and Comfort",
      ProductImages: [
        {
          id: 3,
          type: "full_body",
          thumbnailTitle: "Full Body",
          thumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/mahindra/webp/m7.webp`,
          thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
          thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
          imagesDesktop: [
            {
              id: 13,
              src: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/ekaDesk1.webp`,
            },
            {
              id: 14,
              src: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/ekaDesk2.webp`,
            },
            {
              id: 15,
              src: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/ekaDesk3.webp`,
            },
            {
              id: 16,
              src: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/ekaDesk4.webp`,
            },
            {
              id: 17,
              src: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/ekaDesk5.webp`,
            },
          
          ],
          imagesMobile: [
            {
              id: 11,
              src: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/K_Tech team.webp`,
            },
            {
              id: 12,
              src: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/eka2.webp`,
            },
            {
              id: 13,
              src: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/eka3.webp`,
            },
          ],
        },
      ],
      vehiclePageImage:
        browserType === "Chrome"
          ? `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.avif`
          : `${process.env.NEXT_PUBLIC_CDN_LINK}MahindraVehicleThumbnail/Mahindra-electric2.jpg`,
      galleryIcon:
        browserType === "Chrome"
          ? `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/eka143.webp`
          : `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/gallery_rage_plus.webp`,
      vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandLogo/eka_logo.webp`,
      newLaunchImage: `${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/eka1.webp`,
      image:
        browserType === "Chrome"
          ? `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_flatbed_2.avif`
          : `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_flatbed.jpg`,
      images: [
        {
          id: 10,
          src: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerHiLoadDV130.webp`,
        },
      ],
      view360Images: [
        `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/EulerEulerHiLoad.webp`,
      ],
      link: `https://wa.me/+${whatsappNumber}?text=Hi there!:wave:I am interested in purchasing Montra ePV 2.0 for my business :truck:!`,
      testimonials: {
        customer_1: {
          id: 1,
          name: "Parthasarathi",
          work: "Garment Business",
          city: "Bangalore city",
          description:
            "Turno's representatives even visited my office to collect the necessary documents for the EV loan.",
          description_name: "- Parthasarathi, Garment Business",
          img:
            browserType === "Chrome"
              ? `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.avif`
              : `${process.env.NEXT_PUBLIC_CDN_LINK}Testimonials/mahindra/mahindra-testimonial-1.png`,
          videoLink: "https://www.youtube.com/embed/RwAOk6mWpr0",
        },
      },
    },
    
];

export const vehicleInfo = {
  euler: {
    value: "Euler Hiload",
    link: `https://wa.me/+${whatsappNumber}?text=Hi, I'm interested in purchasing a Euler Hiload for my business!`,
    vehicleId: 1,
    soldInfo: 189,
  },
  mahindra: {
    value: "Mahindra Treo",
    link: `https://wa.me/+${whatsappNumber}?text=Hi, I'm interested in purchasing a Mahindra Treo Zor EV for my business!`,
    vehicleId: 3,
    soldInfo: 204,
  },
  piaggio: {
    value: "Piaggio Ape Electrik",
    link: `https://wa.me/+${whatsappNumber}?text=Hi, I'm interested in purchasing a Piaggio Ape Electrik for my business!`,
    vehicleId: 2,
    soldInfo: 199,
  },
  ETRIO: {
    value: "E-TRIO Touro MAX+",
    link: `https://wa.me/+${whatsappNumber}?text=Hi, I'm interested in purchasing a E-TRIO Touro MAX+ EV for my business!`,
    vehicleId: 4,
    soldInfo: 180,
  },
  alti: {
    value: "AltiGreen neEV",
    link:  `https://wa.me/+${whatsappNumber}?text=Hi, I'm interested in purchasing a Alti Green Neev for my business!`,
    vehicleId: 5,
    soldInfo: 171,
  },
  OSM: {
    value: "OSM Rage+",
    link: `https://wa.me/+${whatsappNumber}?text= Hi, I'm interested in purchasing a OSM Rage+ EV for my business!`,
    vehicleId: 6,
    soldInfo: 182,
  },
};
export const VehicleBrochure = {
  euler: "https://assets.turnoclub.com/developer/brouchers/HiLoad_Brochure.pdf",
  piaggio:
    "https://assets.turnoclub.com/developer/brouchers/Ape-e-xtra_Brochure_English.pdf",
  mahindra:
    "https://assets.turnoclub.com/developer/brouchers/Treo+Zor+Brochure.pdf",
  touro: "https://assets.turnoclub.com/developer/brouchers/E-trio.png",
  altigreen:
    "https://assets.turnoclub.com/developer/brouchers/Altigreen+neEV+Presentation+Feb+2022.pdf",
  rage: "https://assets.turnoclub.com/developer/brouchers/osm-brochhure.pdf",
  etrio: "https://assets.turnoclub.com/developer/brouchers/E-trio.png",
  osm: "https://assets.turnoclub.com/developer/brouchers/Osm_stream_city_qik.pdf",
  "Zor Grand":
    "https://assets.turnoclub.com/developer/brouchers/BrochurezorGrandFinal.pdf",
  "E-Xtra FX Max":
    "https://assets.turnoclub.com/developer/brouchers/Piaggioapexmax.pdf",

  // Need to change

  greaves:
    "https://assets.turnoclub.com/developer/brouchers/greaveseltrabrochure.pdf",
  montra:
    "https://assets.turnoclub.com/developer/brouchers/montraSuperAuto.pdf",
    eka: "https://assets.turnoclub.com/developer/brouchers/EkaBroucher.pdf",
    bajaj: "https://assets.turnoclub.com/developer/brouchers/Bajaj+RE+ETEC+90.pdf",
    tvs: "https://assets.turnoclub.com/developer/brouchers/TVS_King_Ev_Max_Brochure.pdf",
};

export const PricingTableData = [
  {
    model: "Mahindra Treo Zor",
    prices: "₹ 3.4 - 3.9 lakhs",
  },
  {
    model: "Mahindra Zor Grand",
    prices: "₹ 3.9 - 4.4 lakhs",
  },
  {
    model: "Piaggio Ape E-Xtra FX",
    prices: "₹ 3.6 - 4.2 lakhs",
  },
  {
    model: "Piaggio Ape E-Xtra FX Max",
    prices: "₹ 3.9 - 4.3 lakhs",
  },
  {
    model: "E-trio Touro Max++",
    prices: "₹ 3.9 - 4.4 lakhs",
  },
  {
    model: "Euler HiLoad",
    prices: "₹ 4.1 - 4.5 lakhs",
  },
  {
    model: "OSM Rage+ 8.5 kWh",
    prices: "₹ 3.6 - 4.1 lakhs",
  },
  {
    model: "OSM Rage+ 10.8 kWh",
    prices: "₹ 3.9 - 4.4 lakhs",
  },
  {
    model: "OSM Rage+ Frost",
    prices: "From ₹ 8.41 lakhs",
  },
];
export const PricingTableData4W = [
  {
    model: "Eka K1.5",
    prices: "₹ 16.3 - 17.5 lakhs",
  },
  {
    model: "Euler Storm Ev",
    prices: "₹ 9.0 - 13 lakhs",
  },
];

export const BrandLogos = [
  {
    id: 0,
    vehicle_logo: "All Vehicles",
    BrandName: "",
    type: []
  },

  {
    id: 1,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahidra_logo.webp `,
    BrandName: "Mahindra",
    alt: "Mahindra logo",
    type: ["cargo", "passenger"]
  },
  {
    id: 2,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/piaggio_logo.avif`,
    BrandName: "Piaggio",
    alt: "Piaggio logo",
    type: ["cargo", "passenger"]
  },
  {
    id: 3,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/png/Frame.png`,
    BrandName: "Etrio",
    alt: "Etrio logo",
    type: ["cargo"]
  },
  // {
  //   id: 4,
  //   vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/Group-1000004749.avif`,
  //   BrandName: "OSM",
  //   alt: "OSM logo",
  //   type: ["cargo"]
  // },
  {
    id: 5,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/euler_logo.webp`,
    BrandName: "Euler",
    alt: "EUler logo",
    type: ["cargo"]
  },
  {
    id: 6,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreenlogo.webp`,
    BrandName: "Altigreen",
    alt: "Altigreen logo",
    type: ["cargo"]
  },
  {
    id: 7,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandLogo/greaveslogo.webp`,
    BrandName: "Greaves",
    alt: "Greaves logo",
    type: ["cargo"]
  },
  {
    id: 8,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandLogo/Montra_logo.webp`,
    BrandName: "/Montra/Montra-Super-Auto",
    alt: "Montra logo",
    isPassenger: true,
    type: ["passenger"]
  },
  {
    id: 9,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandLogo/Bajaj_logo.webp`,
    BrandName: "Bajaj/BajajRETEC",
    alt: "Bajaj logo",
    isPassenger: true,
    type: ["passenger"]
  },
];

export const ThreeWheelerPassenegerBrandLogos = [
  {
    id: 0,
    vehicle_logo: "All Vehicles",
    BrandName: "",
    type: []
  },

  {
    id: 1,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahidra_logo.webp `,
    BrandName: "Mahindra/MahindraTreoPlus",
    alt: "Mahindra logo",
    type: ["cargo", "passenger"],
    isPassenger: true,
  },
  {
    id: 2,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/piaggio_logo.avif`,
    BrandName: "Piaggio/E-City-FX",
    alt: "Piaggio logo",
    type: ["cargo", "passenger"],
    isPassenger: true,
  },
  {
    id: 3,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandLogo/Montra_logo.webp`,
    BrandName: "/Montra/Montra-Super-Auto",
    alt: "Montra logo",
    isPassenger: true,
    type: ["passenger"]
  },
  {
    id: 4,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandLogo/Bajaj_logo.webp`,
    BrandName: "Bajaj/BajajRETEC",
    alt: "Bajaj logo",
    isPassenger: true,
    type: ["passenger"]
  },
];

export const FourWheelerCargoBrandLogos = [
  {
    id: 0,
    vehicle_logo: "All Vehicles",
    BrandName: "",
    type: []
  },

  {
    id: 1,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandLogo/eka_logo.webp `,
    BrandName: "4w/Eka/K1.5",
    alt: "Eka logo",
    type: ["cargo", "passenger"],
    isFourWheeler: true,
  },
  {
    id: 2,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/euler_logo.webp`,
    BrandName: "4w/Euler/Storm-Ev",
    alt: "EUler logo",
    type: ["cargo", "passenger"],
    isFourWheeler: true,
  },
];


export const BrandLogoImages = {
  Mahindra: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahidra_logo.webp`,
  Montra: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/others/montra_logo.webp`,
  Bajaj: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandLogo/Bajaj_logo.webp`,
  Piaggio: `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/piaggio_logo.avif`,
  Euler: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/euler_logo.webp`,
  Eka: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandLogo/eka_logo.webp`,
  Tvs: `${process.env.NEXT_PUBLIC_CDN_LINK}logos/brands/tvs_logo.svg`,
}
